export var PolicyStatusType;
(function (PolicyStatusType) {
    PolicyStatusType["submitted"] = "submitted";
    PolicyStatusType["quoted"] = "quoted";
    PolicyStatusType["declined"] = "declined";
    PolicyStatusType["withdrawn"] = "withdrawn";
    PolicyStatusType["bound"] = "bound";
    PolicyStatusType["active"] = "active";
    PolicyStatusType["lost"] = "lost";
    PolicyStatusType["grace"] = "grace";
    PolicyStatusType["deleted"] = "deleted";
    PolicyStatusType["cancelled"] = "cancelled";
    PolicyStatusType["reinstated"] = "reinstated";
    PolicyStatusType["prePendingCancellation"] = "prePendingCancellation";
    PolicyStatusType["pendingCancellation"] = "pendingCancellation";
    PolicyStatusType["pendingdeposit"] = "pendingdeposit";
})(PolicyStatusType || (PolicyStatusType = {}));
export const policyStatusList = [
    { code: PolicyStatusType.submitted, description: 'Submitted' },
    { code: PolicyStatusType.quoted, description: 'Quoted' },
    { code: PolicyStatusType.declined, description: 'Declined' },
    { code: PolicyStatusType.withdrawn, description: 'Withdrawn' },
    { code: PolicyStatusType.bound, description: 'Bound' },
    { code: PolicyStatusType.active, description: 'Active' },
    { code: PolicyStatusType.lost, description: 'Lost' },
    { code: PolicyStatusType.grace, description: 'Grace' },
    { code: PolicyStatusType.deleted, description: 'Deleted' },
    { code: PolicyStatusType.cancelled, description: 'Cancelled' },
    { code: PolicyStatusType.reinstated, description: 'Reinstated' },
    { code: PolicyStatusType.prePendingCancellation, description: 'Pre-Pending Cancellation' },
    { code: PolicyStatusType.pendingCancellation, description: 'Pending Cancellation' },
    { code: PolicyStatusType.pendingdeposit, description: 'Pending Deposit' }
];
export const InactiveStatuses = ['cancelled', 'draft', 'pending'];
export var PolicyTemplateSegmentType;
(function (PolicyTemplateSegmentType) {
    PolicyTemplateSegmentType["text"] = "text";
    PolicyTemplateSegmentType["policynumber"] = "policynumber";
})(PolicyTemplateSegmentType || (PolicyTemplateSegmentType = {}));
export const PolicyBoundStatuses = [
    PolicyStatusType.bound,
    PolicyStatusType.prePendingCancellation,
    PolicyStatusType.pendingCancellation,
    PolicyStatusType.reinstated,
    PolicyStatusType.grace,
    PolicyStatusType.pendingdeposit,
    PolicyStatusType.cancelled,
];
export const PolicyActiveBoundStatuses = [
    PolicyStatusType.bound,
    PolicyStatusType.prePendingCancellation,
    PolicyStatusType.pendingCancellation,
    PolicyStatusType.reinstated,
    PolicyStatusType.grace,
];
export var policyTypeEnum;
(function (policyTypeEnum) {
    policyTypeEnum["ClaimsMade"] = "claims-made";
    policyTypeEnum["Occurrence"] = "occurrence";
})(policyTypeEnum || (policyTypeEnum = {}));
;
export const policyTypeList = [
    { code: policyTypeEnum.ClaimsMade, description: 'Claims Made' },
    { code: policyTypeEnum.Occurrence, description: 'Occurrence' }
];
