var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'RateTableInstanceEnhancedDefinition';
let RateTableInstanceEnhancedDefinition = class RateTableInstanceEnhancedDefinition {
    id;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
    rateTableInstanceId;
    sortIndex;
    lookupCriteria;
    rateValueReferences;
    constructor() {
        this.lookupCriteria = [];
        this.rateValueReferences = [];
    }
};
__decorate([
    FieldData({ recordTypeId })
], RateTableInstanceEnhancedDefinition.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], RateTableInstanceEnhancedDefinition.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], RateTableInstanceEnhancedDefinition.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], RateTableInstanceEnhancedDefinition.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], RateTableInstanceEnhancedDefinition.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], RateTableInstanceEnhancedDefinition.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], RateTableInstanceEnhancedDefinition.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], RateTableInstanceEnhancedDefinition.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], RateTableInstanceEnhancedDefinition.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], RateTableInstanceEnhancedDefinition.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'RateTableEnhancedInstance' })
], RateTableInstanceEnhancedDefinition.prototype, "rateTableInstanceId", void 0);
__decorate([
    FieldData({ recordTypeId })
], RateTableInstanceEnhancedDefinition.prototype, "sortIndex", void 0);
__decorate([
    FieldData({
        recordTypeId,
        isList: true,
        jsonStorage: true,
        fieldType: 'jsonb',
    })
], RateTableInstanceEnhancedDefinition.prototype, "lookupCriteria", void 0);
__decorate([
    FieldData({
        recordTypeId,
        isList: true,
        jsonStorage: true,
        fieldType: 'jsonb',
    })
], RateTableInstanceEnhancedDefinition.prototype, "rateValueReferences", void 0);
RateTableInstanceEnhancedDefinition = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Rate Table Rate Definition',
        displayNamePlural: 'Rate Table Rate Definitions',
        dataModelCustomization: {
            hideFromAdmin: true,
        },
        usesNewDataModel: true,
    })
], RateTableInstanceEnhancedDefinition);
export { RateTableInstanceEnhancedDefinition };
