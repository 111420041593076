var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FilterType } from "../../../data-filters/models";
import { RoleCategories } from "../../../roleEnums";
import { TrailMapType } from "../../../trail-maps/models";
import { FieldData, RecordTypeData } from "../../meta-data-decorators";
export var claimStatusEnum;
(function (claimStatusEnum) {
    claimStatusEnum["Open"] = "open";
    claimStatusEnum["Reopened"] = "reopened";
    claimStatusEnum["Closed"] = "closed";
})(claimStatusEnum || (claimStatusEnum = {}));
;
export const claimStatusList = [
    { code: claimStatusEnum.Open, description: 'Open' },
    { code: claimStatusEnum.Reopened, description: 'Reopened' },
    { code: claimStatusEnum.Closed, description: 'Closed' }
];
export var claimTypeEnum;
(function (claimTypeEnum) {
    claimTypeEnum["IncidentOnly"] = "incident-only";
    claimTypeEnum["Medical"] = "Medical";
    claimTypeEnum["Indemnity"] = "Indemnity";
})(claimTypeEnum || (claimTypeEnum = {}));
;
const recordTypeId = 'Claim';
let Claim = class Claim {
    id;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
    claimNumber;
    status;
    coverage;
    claimant;
    lossDate;
    reportDate;
    dateOpened;
    bodyPart;
    adjuster;
    cause;
    nature;
    claimDescription;
    claimType;
    closeDate;
    reserves;
    payments;
    policyId;
    policy;
    //#region Financial
    totalIncurred;
    totalPaid;
    paid1;
    paid2;
    paid3;
    paid4;
    paid5;
    paid6;
    paid7;
    netPaid1;
    netPaid2;
    netPaid3;
    netPaid4;
    netPaid5;
    netPaid6;
    netPaid7;
    reserve1;
    reserve2;
    reserve3;
    reserve4;
    reserve5;
    reserve6;
    reserve7;
    recovery1;
    recovery2;
    recovery3;
    recovery4;
    recovery5;
    recovery6;
    recovery7;
    incurred1;
    incurred2;
    incurred3;
    incurred4;
    incurred5;
    incurred6;
    incurred7;
    outstanding1;
    outstanding2;
    outstanding3;
    outstanding4;
    outstanding5;
    outstanding6;
    outstanding7;
    //#endregion
    policyholderId;
    policyholder;
    incidentId;
    incident;
    lossTime;
    carrierId;
    carrier;
    employerId;
    employer;
    administratorName;
    administratorAddress;
    administratorPhone;
    administratorFein;
    jurisdiction;
    jurisdictionClaimNumber;
    carrierClaimNumber;
    employerNotifiedDate;
    firstCloseDate;
    reopenDate;
    firstReopenDate;
    closeReason;
    reopenReason;
    locationId;
    location;
    //#region WC - Employee
    claimantLastName;
    claimantFirstName;
    claimantMiddleName;
    employeeContactId;
    employeeContact;
    employeeHireDate;
    employeeTerminationDate;
    lastWorkDate;
    disabilityBeginDate;
    returnToWorkDate;
    deathDate;
    initialTreatment;
    //#endregion
    witnesses;
    adjusterUserId;
    adjusterUser;
    occurrenceNumber;
    accidentLocation;
    claimantName;
    temporaryDisabilityRate;
    classCode;
    medicareEligible;
    daysWorked;
    estimatedReturnToWork;
    firstDayLost;
    permenantDisabilityRate;
    vocationalRehabRate;
    wageFrequency;
    wage;
    othersInvolved;
    fullPayOnLastDay;
    fullDayLost;
    employeeContactEmail;
    employeeContactNumber;
    employeeContactAddress;
    employeeContactName;
    employeeDepartment;
    employeeId;
    employmentType;
    claimantOccupation;
    claimantWorkPhone;
    claimantHomePhone;
    claimantCellPhone;
    claimantEmail;
    claimantMailingAddress;
    claimantTaxFilingStatus;
    claimantHicnNumber;
    claimantSocialSecurityNumber;
    numberOfDependents;
    claimantMaritalStatus;
    claimantGender;
    claimantDeathDate;
    claimantAge;
    claimantBirthDate;
    claimantHomeAddress;
    impairmentPercentage;
    impairmentPercentageBasisCode;
    equipmentUsedDuringAccident;
    activityDuringAccident;
    oshaDescription;
    oshaCaseNumber;
    timeEmployeeBeganWork;
    accidentAddress;
    onPremises;
    accidentPremiseCode;
};
__decorate([
    FieldData({ recordTypeId, label: 'Claim ID', fieldType: 'uuid' })
], Claim.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Claim.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Claim.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Claim.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], Claim.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], Claim.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], Claim.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], Claim.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], Claim.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], Claim.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId })
], Claim.prototype, "claimNumber", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'codelist',
        codeList: claimStatusList
    })
], Claim.prototype, "status", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', codeSet: 'coverages' })
], Claim.prototype, "coverage", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Claimant', jsonStorage: true })
], Claim.prototype, "claimant", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], Claim.prototype, "lossDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], Claim.prototype, "reportDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], Claim.prototype, "dateOpened", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', codeSet: 'bodyParts' })
], Claim.prototype, "bodyPart", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, label: 'Adjuster Name' })
], Claim.prototype, "adjuster", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', codeSet: 'causes' })
], Claim.prototype, "cause", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', codeSet: 'naturesOfInjury' })
], Claim.prototype, "nature", void 0);
__decorate([
    FieldData({ recordTypeId })
], Claim.prototype, "claimDescription", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', codeSet: 'claim-types' })
], Claim.prototype, "claimType", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], Claim.prototype, "closeDate", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'ClaimReserve',
        isList: true,
        jsonStorage: true,
        disableField: true
    })
], Claim.prototype, "reserves", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'ClaimPayment',
        isList: true,
        jsonStorage: true,
        disableField: true
    })
], Claim.prototype, "payments", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Policy' })
], Claim.prototype, "policyId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Policy' })
], Claim.prototype, "policy", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], Claim.prototype, "totalIncurred", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], Claim.prototype, "totalPaid", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], Claim.prototype, "paid1", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], Claim.prototype, "paid2", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], Claim.prototype, "paid3", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], Claim.prototype, "paid4", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], Claim.prototype, "paid5", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], Claim.prototype, "paid6", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], Claim.prototype, "paid7", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', virtualDbField: true })
], Claim.prototype, "netPaid1", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', virtualDbField: true })
], Claim.prototype, "netPaid2", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', virtualDbField: true })
], Claim.prototype, "netPaid3", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', virtualDbField: true })
], Claim.prototype, "netPaid4", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', virtualDbField: true })
], Claim.prototype, "netPaid5", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', virtualDbField: true })
], Claim.prototype, "netPaid6", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', virtualDbField: true })
], Claim.prototype, "netPaid7", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], Claim.prototype, "reserve1", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], Claim.prototype, "reserve2", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], Claim.prototype, "reserve3", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], Claim.prototype, "reserve4", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], Claim.prototype, "reserve5", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], Claim.prototype, "reserve6", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], Claim.prototype, "reserve7", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], Claim.prototype, "recovery1", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], Claim.prototype, "recovery2", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], Claim.prototype, "recovery3", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], Claim.prototype, "recovery4", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], Claim.prototype, "recovery5", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], Claim.prototype, "recovery6", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], Claim.prototype, "recovery7", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', virtualDbField: true })
], Claim.prototype, "incurred1", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', virtualDbField: true })
], Claim.prototype, "incurred2", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', virtualDbField: true })
], Claim.prototype, "incurred3", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', virtualDbField: true })
], Claim.prototype, "incurred4", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', virtualDbField: true })
], Claim.prototype, "incurred5", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', virtualDbField: true })
], Claim.prototype, "incurred6", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', virtualDbField: true })
], Claim.prototype, "incurred7", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', virtualDbField: true })
], Claim.prototype, "outstanding1", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', virtualDbField: true })
], Claim.prototype, "outstanding2", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', virtualDbField: true })
], Claim.prototype, "outstanding3", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', virtualDbField: true })
], Claim.prototype, "outstanding4", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', virtualDbField: true })
], Claim.prototype, "outstanding5", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', virtualDbField: true })
], Claim.prototype, "outstanding6", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', virtualDbField: true })
], Claim.prototype, "outstanding7", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'PolicyHolder' })
], Claim.prototype, "policyholderId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'PolicyHolder' })
], Claim.prototype, "policyholder", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Incident' })
], Claim.prototype, "incidentId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Incident' })
], Claim.prototype, "incident", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'time' })
], Claim.prototype, "lossTime", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Carrier' })
], Claim.prototype, "carrierId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Carrier' })
], Claim.prototype, "carrier", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Employer' })
], Claim.prototype, "employerId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Employer' })
], Claim.prototype, "employer", void 0);
__decorate([
    FieldData({ recordTypeId })
], Claim.prototype, "administratorName", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Address', jsonStorage: true })
], Claim.prototype, "administratorAddress", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'phone-number' })
], Claim.prototype, "administratorPhone", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Administrator FEIN' })
], Claim.prototype, "administratorFein", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', codeSet: 'states' })
], Claim.prototype, "jurisdiction", void 0);
__decorate([
    FieldData({ recordTypeId })
], Claim.prototype, "jurisdictionClaimNumber", void 0);
__decorate([
    FieldData({ recordTypeId })
], Claim.prototype, "carrierClaimNumber", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], Claim.prototype, "employerNotifiedDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], Claim.prototype, "firstCloseDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], Claim.prototype, "reopenDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], Claim.prototype, "firstReopenDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', codeSet: 'claim-close-reasons' })
], Claim.prototype, "closeReason", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', codeSet: 'claim-reopen-reasons' })
], Claim.prototype, "reopenReason", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Location' })
], Claim.prototype, "locationId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Location' })
], Claim.prototype, "location", void 0);
__decorate([
    FieldData({ recordTypeId })
], Claim.prototype, "claimantLastName", void 0);
__decorate([
    FieldData({ recordTypeId })
], Claim.prototype, "claimantFirstName", void 0);
__decorate([
    FieldData({ recordTypeId })
], Claim.prototype, "claimantMiddleName", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Contact' })
], Claim.prototype, "employeeContactId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Contact', contactType: 'employee' })
], Claim.prototype, "employeeContact", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], Claim.prototype, "employeeHireDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], Claim.prototype, "employeeTerminationDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], Claim.prototype, "lastWorkDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], Claim.prototype, "disabilityBeginDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], Claim.prototype, "returnToWorkDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], Claim.prototype, "deathDate", void 0);
__decorate([
    FieldData({
        recordTypeId, fieldType: 'codelist', isList: true, jsonStorage: true, codeList: [
            { code: '0', description: 'No Medical Treatment' },
            { code: '1', description: 'Minor by Employer' },
            { code: '2', description: 'Minor Clinic/Hospital' },
            { code: '3', description: 'Emergency Care' },
            { code: '4', description: 'Hospitalized > 24 Hours' },
            { code: '5', description: 'Future Major Medical / Lost Time Anticipated' },
        ]
    })
], Claim.prototype, "initialTreatment", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Witness', isList: true, jsonStorage: true })
], Claim.prototype, "witnesses", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'User' })
], Claim.prototype, "adjusterUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'User', label: 'Adjuster', userTags: ['adjuster'] })
], Claim.prototype, "adjusterUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string' })
], Claim.prototype, "occurrenceNumber", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Address', jsonStorage: true })
], Claim.prototype, "accidentLocation", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', })
], Claim.prototype, "claimantName", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', })
], Claim.prototype, "temporaryDisabilityRate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', codeSet: 'class-codes' })
], Claim.prototype, "classCode", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean', })
], Claim.prototype, "medicareEligible", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'integer', })
], Claim.prototype, "daysWorked", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date', })
], Claim.prototype, "estimatedReturnToWork", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date', })
], Claim.prototype, "firstDayLost", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', })
], Claim.prototype, "permenantDisabilityRate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', })
], Claim.prototype, "vocationalRehabRate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', codeSet: 'wage-frequencies' })
], Claim.prototype, "wageFrequency", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', })
], Claim.prototype, "wage", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean', })
], Claim.prototype, "othersInvolved", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean', })
], Claim.prototype, "fullPayOnLastDay", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean', })
], Claim.prototype, "fullDayLost", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'email', })
], Claim.prototype, "employeeContactEmail", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'phone-number', })
], Claim.prototype, "employeeContactNumber", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Address', jsonStorage: true })
], Claim.prototype, "employeeContactAddress", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', })
], Claim.prototype, "employeeContactName", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', })
], Claim.prototype, "employeeDepartment", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', })
], Claim.prototype, "employeeId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', codeSet: 'employment-types' })
], Claim.prototype, "employmentType", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', })
], Claim.prototype, "claimantOccupation", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'phone-number', })
], Claim.prototype, "claimantWorkPhone", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'phone-number', })
], Claim.prototype, "claimantHomePhone", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'phone-number', })
], Claim.prototype, "claimantCellPhone", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'email', })
], Claim.prototype, "claimantEmail", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Address', jsonStorage: true })
], Claim.prototype, "claimantMailingAddress", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', codeSet: 'tax-filing-statuses' })
], Claim.prototype, "claimantTaxFilingStatus", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', })
], Claim.prototype, "claimantHicnNumber", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', })
], Claim.prototype, "claimantSocialSecurityNumber", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'integer', })
], Claim.prototype, "numberOfDependents", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', codeSet: 'marital-statuses' })
], Claim.prototype, "claimantMaritalStatus", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', codeSet: 'genders' })
], Claim.prototype, "claimantGender", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date', })
], Claim.prototype, "claimantDeathDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'integer', })
], Claim.prototype, "claimantAge", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date', })
], Claim.prototype, "claimantBirthDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Address', jsonStorage: true })
], Claim.prototype, "claimantHomeAddress", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'percentage-whole-number', })
], Claim.prototype, "impairmentPercentage", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', codeList: [{ code: '1', description: 'Whole of Body' }, { code: '2', description: 'Part of Body' }] })
], Claim.prototype, "impairmentPercentageBasisCode", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', })
], Claim.prototype, "equipmentUsedDuringAccident", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', })
], Claim.prototype, "activityDuringAccident", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', })
], Claim.prototype, "oshaDescription", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', })
], Claim.prototype, "oshaCaseNumber", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'time', })
], Claim.prototype, "timeEmployeeBeganWork", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Address', jsonStorage: true })
], Claim.prototype, "accidentAddress", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean', })
], Claim.prototype, "onPremises", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', codeSet: 'accident-premises' })
], Claim.prototype, "accidentPremiseCode", void 0);
Claim = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        usesNewDataModel: true,
        displayNameSingular: 'Claim',
        displayNamePlural: 'Claims',
        roleCategory: RoleCategories.Claims,
        security: {
            useDefaultPersonaSecurity: true,
            allowCrossPolicyholderRecords: false
        },
        recordHistoryConfig: {
            enable: true
        },
        globalSearchPaths: [
            'claimNumber',
            'claimDescription',
            'claimant.lastName',
            'claimant.firstName',
            'claimantLastName',
            'claimantFirstName'
        ],
        workflow: {
            enabled: true,
            generalEvents: true,
            otherEvents: ['CLAIM-CLOSED', 'CLAIM-REOPENED']
        },
        trailMapTypes: [TrailMapType.DataEntry, TrailMapType.Detail, TrailMapType.HomePage, TrailMapType.DataEntryStart],
        dataModelCustomization: {
            allowCustomFields: true
        },
        lookupProperties: { resultProperties: ['claimNumber', 'lossDate'], filterProperties: ['claimNumber'] },
        header: {
            title: ['claimNumber', 'status'],
            subTitle: ['lossDate', 'claimantName']
        },
        quickFilterFields: [
            { recordType: recordTypeId, fieldPath: 'claimNumber', filterType: FilterType.Equals },
            { recordType: recordTypeId, fieldPath: 'claimantName', filterType: FilterType.Contains },
            { recordType: recordTypeId, fieldPath: 'status', filterType: FilterType.Equals },
            { recordType: recordTypeId, fieldPath: 'claimType', filterType: FilterType.Equals },
            { recordType: recordTypeId, fieldPath: 'coverage', filterType: FilterType.Equals },
            { recordType: recordTypeId, fieldPath: 'claimDescription', filterType: FilterType.Contains },
            { recordType: recordTypeId, fieldPath: 'policy.policyNumber', filterType: FilterType.Equals },
        ],
        supports: {
            docTemplates: true,
            files: true,
            views: true,
            notes: true,
            tasks: true,
            contacts: true,
            globalSearch: true
        }
    })
], Claim);
export { Claim };
