import { Observable, shareReplay } from 'rxjs';
import { ChangePasswordRequest } from 'src/app/core/models/change-password-request';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { MyUserDetailsInput } from 'portal-commons/dist/users/models';
import { UserPreview } from 'portal-commons/dist/identity/models/userPreview';
import { User } from 'portal-commons/dist/data-model/record-types/user';
import { PaginatedTableOptions } from 'portal-commons/dist/data-table/models';

export interface selectGroup {
  id: string;
  label: string;
}
@Injectable({
  providedIn: 'root',
})
export class UserDetailsService {
  httpClient = inject(HttpClient);

  getPaginatedUsers(options: PaginatedTableOptions) {
    return this.httpClient
      .get<User[]>('/api/users', {
        params: {
          options: JSON.stringify(options),
        },
      })
      .pipe(shareReplay(1));
  }

  get(id: string) {
    return this.httpClient.get<User>(`/api/users/${id}`).pipe(shareReplay(1));
  }

  saveProfile(input: MyUserDetailsInput) {
    return this.httpClient.post<User>('/api/myprofile', input).pipe(shareReplay(1));
  }

  saveUser(input: Partial<User>) {
    return this.httpClient.post<User>('/api/users', input).pipe(shareReplay(1));
  }

  deleteUser(id: string) {
    return this.httpClient
      .post<User>(`/api/users/delete`, { id: id })
      .pipe(shareReplay(1));
  }

  reactivateUser(id: string) {
    return this.httpClient
      .post<User>(`/api/users/reactivate`, { id: id })
      .pipe(shareReplay(1));
  }

  changePassword(token: any, passwordBody: ChangePasswordRequest): Observable<any> {
    const path = '/api/users/password';

    return this.httpClient.put(path, passwordBody, {
      headers: { Authorization: token },
    });
  }
  getUserPreviews(forceRefresh: boolean): Observable<UserPreview[]> {
    return this.httpClient
      .get<UserPreview[]>('api/userpreviews')
      .pipe(shareReplay(1));
  }

  distinctGroups(userPreviews: UserPreview[]): selectGroup[] {
    const groups: selectGroup[] = [];
    if (userPreviews) {
      for (const user of userPreviews) {
        const group: selectGroup = {
          id: '',
          label: '',
        };
        if (user.agencyId && user.agencyPreview) {
          group.id = user.agencyId;
          group.label = user.agencyPreview.name;
        }
        const existing = groups.find((e) => e.id === group.id);
        if (!existing) {
          groups.push(group);
        }
      }
    }

    return groups;
  }
}
