import { inject, Injectable } from '@angular/core';
import { DataModelRecordType } from 'portal-commons/dist/data-model/record-types';
import { AuthService } from '../auth/auth.service';
import { DataModelStoreService } from '../data-model/services/data-model.store';
import { RecordLinkConfig, routeDefs } from 'portal-commons/dist/data-model/routes';

@Injectable({
  providedIn: 'root'
})
export class RecordLinkService {
  authService = inject(AuthService);
  dm = inject(DataModelStoreService);

  supportsViewLink(recordType: DataModelRecordType): boolean {
    return !!this.getViewRoute(recordType);
  }

  private getRouteConfig(recordType: DataModelRecordType): RecordLinkConfig | undefined {
    if (recordType.scope === 'tenant' && recordType.alias) {
      return { route: ['tenant', recordType.alias, '@id'] };
    }
    if (routeDefs.has(recordType.id.toLowerCase())) {
      return routeDefs.get(recordType.id.toLowerCase());
    }
    return undefined;
  }

  private getViewRoute(recordType: DataModelRecordType): string | undefined {
    const config = this.getRouteConfig(recordType);
    if (config) { return `/${config.route.join('/')}`; }
    return undefined;
  }

  getRecordTypeViewLink(recordTypeId: string | DataModelRecordType, model: string | object | undefined) {
    if (!recordTypeId || !model) { return undefined; }
    const recordType = typeof recordTypeId === 'string' ? this.dm.getRecordType(recordTypeId) : recordTypeId;
    if (!recordType) { return undefined; }
    if (recordType.roleCategory && !this.authService.hasFeatureAccess(recordType.roleCategory)) { return undefined; }
    const config = this.getRouteConfig(recordType);
    if (!config) { return undefined; }
    const idVal: string = typeof model === 'string' ? model : (model as any)[config.idProperty ?? 'id'];
    if (!!!idVal) { return undefined; }
    return `/${config.route.join('/').replace('@id', idVal)}`;
  }
}
