
export const environment =  {
  "firebase": {
    "apiKey": "AIzaSyA_Qk_qw8Dhf_n7Vrr24kn5pSz6sdYlbcg",
    "authDomain": "trailblazer-portal.firebaseapp.com",
    "projectId": "trailblazer-portal",
    "storageBucket": "trailblazer-portal.appspot.com",
    "messagingSenderId": "491868144525",
    "appId": "1:491868144525:web:e1fd78c30f6822d9c773c4",
    "measurementId": "GTM-T5QZRP2",
    "GTM_EnvironmentAuth": "5Q_lbaOnf4fNtY0c3BcbHQ",
    "GTM_Preview": "env-7"
  },
  "vapidKey": "BC4qttnMHf0GG3EVRbT7QU7OHbQ-4tJpzWpArb2bmRa3CYujPhr4gOIpDG7qa-2n4MgKr7dkQoMegacwKp0xHlA",
  "production": false,
  "env": "dev",
  "inAws": true,
  "baseHostName": "uat.app.trailblazertech.com",
  "apiOriginDomain": "https://uat.app.trailblazertech.com",
  "apiOriginPath": "/api",
  "wsDomainOverride": "",
  "wsPath": "/ws",
  "awsRegion": "us-east-1",
  "cognitoUserpoolId": "us-east-1_INzP1y6zp",
  "cognitoWebclientId": "72fieh13a0huv42jc7qc5anjtm",
  "cognitoDomain": "tb-app.auth.us-east-1.amazoncognito.com",
  "pdfjsExpressKey": "8PFzc4SvaPZNoKXfYcMC",
  "apryseKey": "Trailblazer Insurance Technology Inc.:OEM:Trailblazer::B+:AMS(20250505):BEA57C5D04A7C60A8360B13AE981537160617FD5C7001A33CD2205531C0418AE328431F5C7",
  "assetsUrl": "https://assets.trailblazertech.com",
  "sourceSHA": "274272d85fee5ea115cd4f1616613ee77460e293",
  "captcha": "6Lda-q0pAAAAAIbPilJ4VfuavzvZwPOdAbjsYZJt"
};
