var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../../meta-data-decorators';
import { RoleCategories } from '../../../roleEnums';
const recordTypeId = 'ClaimFinancialBucket';
let ClaimFinancialBucket = class ClaimFinancialBucket {
    bucket1;
    bucket2;
    bucket3;
    bucket4;
    bucket5;
    bucket6;
    bucket7;
};
__decorate([
    FieldData({ recordTypeId })
], ClaimFinancialBucket.prototype, "bucket1", void 0);
__decorate([
    FieldData({ recordTypeId })
], ClaimFinancialBucket.prototype, "bucket2", void 0);
__decorate([
    FieldData({ recordTypeId })
], ClaimFinancialBucket.prototype, "bucket3", void 0);
__decorate([
    FieldData({ recordTypeId })
], ClaimFinancialBucket.prototype, "bucket4", void 0);
__decorate([
    FieldData({ recordTypeId })
], ClaimFinancialBucket.prototype, "bucket5", void 0);
__decorate([
    FieldData({ recordTypeId })
], ClaimFinancialBucket.prototype, "bucket6", void 0);
__decorate([
    FieldData({ recordTypeId })
], ClaimFinancialBucket.prototype, "bucket7", void 0);
ClaimFinancialBucket = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: false,
        displayNameSingular: 'Claim Financial Buckets',
        displayNamePlural: 'Claim Financial Buckets',
        roleCategory: RoleCategories.Claims,
        dataModelCustomization: {
            allowCustomFields: false,
            preventAddCustomFields: true
        }
    })
], ClaimFinancialBucket);
export { ClaimFinancialBucket };
