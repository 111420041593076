import { AuthService, AuthState } from 'app/core/auth/auth.service';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { EMPTY, Subject, catchError, firstValueFrom, map, takeUntil, tap } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AgencyService } from 'app/core/services/agency.service';
import { CustomValidators } from 'app/core/validators/custom-validators';
import { FuseValidators } from '@fuse/validators';
import { IsLoadingService } from '@service-work/is-loading';
import { MIN_PASSWORD_LENGTH } from 'app/core/models/idp-state';
import { MatDialogRef } from '@angular/material/dialog';
import { MyUserDetailsInput } from 'portal-commons/dist/users/models';
import { ToastNotificationService } from 'app/core/notifications/toasts/toast-notification.service';
import { UserDetailsService } from 'src/app/modules/admin/users/services/user-details.service';

export interface Timezone {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit, OnDestroy {
  user: AuthState;
  timezones: Timezone[] = [
    { value: 'EST', viewValue: 'Eastern Standard Time (EST)' },
    { value: 'CST', viewValue: 'Central Standard Time (CST)' },
    { value: 'MST', viewValue: 'Mountain Standard Time (MST)' },
    { value: 'PST', viewValue: 'Pacific Standard Time (PST)' },
  ];

  profileLoadingKey = 'user-profile-profile-submit';
  passwordChangeKey = 'user-profile-password-submit';

  MIN_PASSWORD_LENGTH = MIN_PASSWORD_LENGTH;
  profileForm: FormGroup;
  changePasswordForm: FormGroup;
  lockEmail: boolean;
  agencyName: string;
  destroy$ = new Subject<void>();

  /**
   * Constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    public matDialogRef: MatDialogRef<UserProfileComponent>,
    private loadingService: IsLoadingService,
    private userDetailsService: UserDetailsService,
    private authService: AuthService,
    private notificationService: ToastNotificationService,
    private ref: ChangeDetectorRef,
    private agencyService: AgencyService,
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  async ngOnInit() {
    this.profileForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      fullName: [''],
      timezone: [''],
    });

    this.changePasswordForm = this.formBuilder.group(
      {
        oldPassword: ['', [Validators.required]],
        newPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(MIN_PASSWORD_LENGTH),
            CustomValidators.patternValidator(/\d/, { hasNumber: true }),
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true,
            }),
            CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
            CustomValidators.patternValidator(/[ ^$*.\[\]{}()?"!@#%&\/\[\],><':;|_~`=+-]/, {
              hasSpecialCharacters: true,
            }),
          ],
        ],
        newPasswordConfirm: ['', [Validators.required]],
      },
      {
        validators: FuseValidators.mustMatch('newPassword', 'newPasswordConfirm'),
      },
    );

    await this.getUser();
  }

  async getUser() {
    this.loadingService.add({ key: this.profileLoadingKey });
    const userData = (await this.authService.getCurrentDetails()) as AuthState;

    //todo is AuthState correct obj for this?  might be missing/diff props.  remove any
    const _user: AuthState = {
      ...userData,
    };
    this.profileForm.patchValue(_user);
    if (_user?.id?.toLowerCase() === _user?.email?.toLowerCase()) {
      // Cognito does not allow change of username so if email matches username then we will restrict
      this.lockEmail = true;
    }

    if (_user.agencyId) {
      this.agencyName = await firstValueFrom(
        this.agencyService.getAgency(_user.agencyId, false).pipe(
          takeUntil(this.destroy$),
          map((agency) => agency.name),
        ),
      );
    }

    this.loadingService.remove({ key: this.profileLoadingKey });
    this.user = _user;
    this.ref.markForCheck();
  }

  /**
   * On destroy
   */
  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  updateUser(): void {
    if (!this.profileForm.valid) {
      return;
    }

    this.loadingService.add({ key: this.profileLoadingKey });
    const user = this.authService.currentUser();

    if (this.profileForm) {
      const update: MyUserDetailsInput = {
        ...this.profileForm.getRawValue(),
        userId: user.id,
      };

      this.loadingService.add(
        this.userDetailsService.saveProfile(update).pipe(
          tap((result) => {
            this.notificationService.success('Profile updated successfully');
            this.loadingService.remove({ key: this.profileLoadingKey });
          }),
          catchError((err) => {
            this.notificationService.error('Could not update profile. ' + err.message);
            this.loadingService.remove({ key: this.profileLoadingKey });
            return EMPTY;
          }),
        ),
        { key: this.profileLoadingKey },
      );
    }
  }

  /**
   * Update the user's password
   *
   * @param status
   */
  changePassword(): void {
    if (!this.changePasswordForm.valid) {
      return;
    }

    this.loadingService.add({ key: this.passwordChangeKey });
    const user = this.authService.currentUser();
    if (this.changePasswordForm) {
      const formVals = this.changePasswordForm.getRawValue();

      this.authService
        .changePassword(formVals.oldPassword, formVals.newPassword)
        .then((value) => {
          this.loadingService.remove({ key: this.passwordChangeKey });
          this.notificationService.success('Password updated successfully');
          this.changePasswordForm.reset();
          this.changePasswordForm.controls.oldPassword.setErrors(null);
          this.changePasswordForm.controls.newPassword.setErrors(null);
          this.changePasswordForm.controls.newPasswordConfirm.setErrors(null);
        })
        .catch((err) => {
          this.loadingService.remove({ key: this.passwordChangeKey });
          console.error("error updating the user's profile :: " + err.message);
          this.notificationService.error('Could not update password. ' + err.message);
        });
    }
  }

  close() {
    this.matDialogRef.close();
    return;
  }
}
