import { inject, Injectable } from '@angular/core';
import { map, Observable, of, ReplaySubject, shareReplay } from 'rxjs';
import { Navigation } from './navigation.types';
import { cloneDeep } from 'lodash-es';
import { AuthService, AuthState } from 'app/core/auth/auth.service';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { groupedNavigation } from './models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NavigationConfig, NavigationConfigItem } from 'portal-commons/dist/navigation/models';
import { DataModelStoreService } from '../data-model/services/data-model.store';
import { TrailMapType } from 'portal-commons/dist/trail-maps/models';
import { RoleCategories } from 'portal-commons/dist/roleEnums';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);
  private _basicItems: FuseNavigationItem[] | undefined = undefined;
  dataModelStore = inject(DataModelStoreService);

  constructor(private authService: AuthService) {
    this.seedBasicItems();
  }

  getTopLevelGroups() {
    const groups = [...groupedNavigation.filter(f => f.type === 'aside')];
    return groups;
  }

  getBasicItemsAsConfigItems() {
    const base = [...this._basicItems!] as NavigationConfigItem[];
    const tenantTypes = Array.from(this.dataModelStore.getRecordTypes()?.values() ?? []).filter(f => f.scope && f.scope === 'tenant' && f.hasBackingTable && f.trailMapTypes?.includes(TrailMapType.HomePage));
    for (const tenantType of tenantTypes) {
      if (!tenantType.alias) { continue; }
      base.push(
        {
          id: tenantType.alias,
          title: tenantType.displayNamePlural,
          scope: 'tenant',
          link: `/tenant/${tenantType.alias}`,
          type: 'basic',
          icon: tenantType.navMenuConfiguration?.icon.icon,
          featureCategory: tenantType.alias
        }
      );
    }
    return base;
  }

  seedBasicItems() {
    this._basicItems = this.filterBasicItems();
  }

  filterBasicItems() {
    const basicMap = new Map<string, FuseNavigationItem>();
    for (const item of groupedNavigation) {
      if (item.type === 'basic' && item.id) {
        basicMap.set(item.id, item);
      }
      else if (item.type === 'aside' && (item.children?.length ?? 0) > 0) {
        for (const child of item.children!) {
          if (child.type === 'basic' && child.id) {
            basicMap.set(child.id, child);
          }
        }
      }
    }
    return Array.from(basicMap.values());
  }

  get navigation$(): Observable<Navigation> {
    return this._navigation.asObservable();
  }

  get(): Observable<Navigation> {
    this.seedNavigation();
    return this.navigation$;
  }

  filterNavLinks(search: string, categories: string[]) {
    if (!categories.includes('*') && !categories.includes('nav')) { return of([]); }
    return this.navigation$.pipe(
      untilDestroyed(this),
      map((items) => {
        return this.searchMenuItems(search, items.default);
      }),
      shareReplay(1),
    );
  }

  private seedNavigation() {
    this.pruneMenuItems(this.authService.currentUser()).subscribe((next) => {
      this._navigation.next(next);
      this._navigation.complete();
    });
  }

  private searchMenuItems(search: string, items: FuseNavigationItem[]): FuseNavigationItem[] {
    const filteredItems: FuseNavigationItem[] = [];
    for (let i = 0; i < items.length; i++) {
      const menuItem = { ...items[i] };
      if (
        (menuItem.link && menuItem.title?.toLocaleLowerCase().includes(search)) ||
        menuItem.subtitle?.toLocaleLowerCase().includes(search)
      ) {
        filteredItems.push({
          type: menuItem.type,
          title: menuItem.title,
          subtitle: menuItem.subtitle,
          link: menuItem.link,
        });
      }

      if (menuItem.children) {
        const filteredChildren = this.searchMenuItems(search, menuItem.children);
        if (filteredChildren.length > 0) {
          filteredItems.push(...filteredChildren);
        }
      }
    }
    return filteredItems;
  }

  private filterMenuItems(authState: AuthState, items: FuseNavigationItem[]): FuseNavigationItem[] {
    const filteredItems: FuseNavigationItem[] = [];
    for (let i = 0; i < items.length; i++) {
      const menuItem = cloneDeep(items[i]);
      if (menuItem.persona) {
        if (authState.persona !== menuItem.persona) {
          continue;
        }
      }

      if (menuItem.featureCategory) {
        if (!this.authService.hasFeatureAccess(menuItem.featureCategory)) {
          continue;
        }
        if (menuItem.featurePermissionOr && menuItem.featurePermissionOr.length > 0) {
          let hasAtLeastOne = false;
          for (const perm of menuItem.featurePermissionOr) {
            if (this.authService.hasPermission(menuItem.featureCategory, perm)) {
              hasAtLeastOne = true;
              break;
            }
          }
          if (!hasAtLeastOne) {
            continue;
          }
        }
      }

      if (menuItem.children) {
        const filteredChildren = this.filterMenuItems(authState, menuItem.children);
        if (filteredChildren.length === 0) {
          continue;
        }
        menuItem.children = filteredChildren;
      }
      filteredItems.push(menuItem);
    }
    return filteredItems;
  }

  private pruneMenuItems(
    authState: AuthState,
  ): Observable<Navigation> {
    const items: FuseNavigationItem[] = [];

    if (authState.role) {
      const coreItems = this.getRoleNavigation(authState.role.navigation);
      items.push(...this.filterMenuItems(authState, cloneDeep(coreItems)));
    }

    for (const [index, item] of items.entries()) {
      if (item.children && item.children.length === 1) {
        if (item.meta && item.meta.showGroup) {
          continue;
        }
        items[index] = item.children[0];
      }
    }

    return of({
      compact: cloneDeep(items),
      default: cloneDeep(items),
      futuristic: cloneDeep(items),
      horizontal: cloneDeep(items),
    });
  }

  getDefaultNavigation() {
    const baseline = [...groupedNavigation];
    const tenantTypes = Array.from(this.dataModelStore.getRecordTypes()?.values() ?? []).filter(f =>
      f.scope && f.scope === 'tenant' && f.hasBackingTable && f.trailMapTypes?.includes(TrailMapType.HomePage)
      && f.navMenuConfiguration && f.navMenuConfiguration.icon);
    for (const tenantType of tenantTypes) {
      if (!tenantType.alias) { continue; }

      if (!!tenantType.navMenuConfiguration?.partOfGroup) {
        const groupMatchIndex = baseline.findIndex(f => f.id === tenantType.navMenuConfiguration?.partOfGroup);
        if (groupMatchIndex >= 0) {
          const groupMatch = cloneDeep(baseline.find(f => f.id === tenantType.navMenuConfiguration?.partOfGroup));
          if (groupMatch && groupMatch.children) {
            groupMatch.children.push({
              id: tenantType.alias,
              title: tenantType.displayNamePlural,
              link: `/tenant/${tenantType.alias}`,
              type: 'basic',
              icon: tenantType.navMenuConfiguration?.icon.icon,
              featureCategory: tenantType.alias as RoleCategories
            });
            baseline[groupMatchIndex] = groupMatch;
            continue;
          }
        }
      }

      baseline.push({
        id: tenantType.alias,
        title: tenantType.displayNamePlural,
        link: `/tenant/${tenantType.alias}`,
        type: 'basic',
        icon: tenantType.navMenuConfiguration?.icon.icon,
        featureCategory: tenantType.alias as RoleCategories
      });
    }
    return baseline;
  }

  private getRoleNavigation(config: NavigationConfig | undefined): FuseNavigationItem[] {
    if (!config) { return groupedNavigation; }
    if (config && config.useDefault) { return this.getDefaultNavigation(); }
    const fuseConfig: FuseNavigationItem[] = [];
    for (const configItem of config.items) {
      // eslint-disable-next-line default-case
      switch (configItem.type) {
        case 'basic': {
          const baseItem = this._basicItems?.find(f => f.id === configItem.id);
          if (baseItem) {
            fuseConfig.push({
              ...baseItem,
              defaultPage: configItem.defaultPage ?? false
            });
          }
          else {
            if (configItem.scope === 'tenant') {
              const tenantRec = this.dataModelStore.getRecordType(configItem.id);
              if (tenantRec && tenantRec.navMenuConfiguration?.icon) {
                configItem.icon = tenantRec.navMenuConfiguration?.icon.icon;
              }
            }
            fuseConfig.push({
              ...configItem as FuseNavigationItem,
              featureCategory: configItem.featureCategory as RoleCategories,
              defaultPage: configItem.defaultPage ?? false
            });
          }
          break;
        }
        case 'aside': {
          const groupNode: FuseNavigationItem = {
            ...configItem,
            featureCategory: (configItem.featureCategory as RoleCategories) ?? undefined,
            children: []
          };
          for (const configChild of (configItem.children ?? [])) {
            const baseItem = this._basicItems?.find(f => f.id === configChild.id);
            if (baseItem) {
              groupNode.children!.push({
                ...baseItem,
                defaultPage: configChild.defaultPage ?? false
              });
            }
            else {
              groupNode.children!.push({
                ...configChild as FuseNavigationItem,
                featureCategory: configChild.featureCategory as RoleCategories,
                defaultPage: configChild.defaultPage ?? false
              });
            }
          }
          fuseConfig.push(groupNode);
          break;
        }
      }
    }
    return fuseConfig;
  }
}
