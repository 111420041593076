import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { BehaviorSubject, Observable, filter, firstValueFrom, map, tap } from 'rxjs';
import { TableSchemaField } from 'portal-commons/dist/views/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MetadataFieldType, RecordTypeFieldMetadata } from 'app/core/data-model/models/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DataModelStoreService } from 'app/core/data-model/services/data-model.store';
import type { MaskitoOptions } from '@maskito/core';
import maskDate from '../../../../shared/masks/datemask';
import { IsLoadingService } from '@service-work/is-loading';
import { getDateOnlyString } from 'app/core/utils/form-helper';
import {
  Formula,
  FormulaComponent,
  FormulaOperator,
  FormulaValueType,
} from 'portal-commons/dist/data-model/formula';
import { LookupRecord } from 'app/modules/data-model/models/model';
import { BasicFieldTypes } from 'portal-commons/dist/data-model/record-types';
import { CustomValidators } from 'app/core/validators/custom-validators';

@UntilDestroy()
@Component({
  selector: 'tb-formula-component',
  templateUrl: './formula-component.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormulaComponentComponent implements OnInit, AfterViewInit {
  @Input() recordTypeId$!: Observable<string>;
  @Input() depth!: number;
  @Input() index!: number;
  @Input() component!: FormulaComponent;
  @Input() outputFormat$!: Observable<BasicFieldTypes>;
  @Input() allowOperators$!: Observable<boolean>;
  @Input() rootFieldsOnly = false;
  @Input() includeListFields = false;
  @Output() deleteComponent = new EventEmitter<number>();
  @Output() componentUpdated = new EventEmitter<FormulaComponent>();
  readonly options: MaskitoOptions = maskDate;

  form!: FormGroup;
  _field = new BehaviorSubject<RecordTypeFieldMetadata | undefined>(undefined);
  field$ = this._field.asObservable();

  FormulaValueType = FormulaValueType;

  private _recordTypes = new BehaviorSubject<LookupRecord[]>([]);
  recordTypes$ = this._recordTypes.asObservable();

  private _fieldTypeFilter = new BehaviorSubject<BasicFieldTypes[]>([]);
  fieldTypeFilter$ = this._fieldTypeFilter.asObservable();

  loadingService = inject(IsLoadingService);
  LOADING_KEY = crypto.randomUUID();

  operators = [
    { text: '+', value: FormulaOperator.Add },
    { text: '-', value: FormulaOperator.Subtract },
    { text: 'x', value: FormulaOperator.Multiply },
    { text: '/', value: FormulaOperator.Divide },
  ];

  private _additionalLabel = new BehaviorSubject<string | undefined>(undefined);
  readonly additionalLabel$ = this._additionalLabel.asObservable();

  constructor(
    private builder: FormBuilder,
    private _dataModelStore: DataModelStoreService,
    private ref: ChangeDetectorRef,
  ) {}

  async ngOnInit() {
    this.form = this.builder.group({
      type: this.component.type,
      operator: [
        undefined,
        [CustomValidators.conditionalValidator(() => this.index > 0, Validators.required)],
      ],
      value: [],
    });

    if (this.index > 0 && !this.component.operator) {
      this.form.get('operator')?.setValue(FormulaOperator.Add);
      this.component.operator = FormulaOperator.Add;
    }

    this.setFieldTypeFilter(await firstValueFrom(this.outputFormat$));

    this.outputFormat$.pipe(tap((x) => this.setFieldTypeFilter(x))).subscribe();

    this.form.valueChanges
      .pipe(
        untilDestroyed(this),
        filter((_) => this.form.valid),
        map((x) => {
          this.updateModelFromForm();
        }),
      )
      .subscribe();
  }

  ngAfterViewInit() {
    this.updateFormFromModel();
  }

  setFieldTypeFilter(outputFormat: BasicFieldTypes) {
    switch (outputFormat) {
      case 'integer':
      case 'currency':
      case 'decimal':
        this._fieldTypeFilter.next(['integer', 'currency', 'decimal']);
        break;
      default:
        this._fieldTypeFilter.next([]);
        break;
    }
  }

  async fieldChanged(value: RecordTypeFieldMetadata | null) {
    if (value) {
      const map: TableSchemaField = {
        fieldLabel: value.label,
        name: value.label,
        fieldId: value.id,
        fieldRecordType: value.recordTypeId,
        fieldRefName: value.fieldRefName ?? value.refName,
        mappedObject: value.refName,
        fieldType: value.dataModelFieldType,
      };
      this.form.get('value')?.setValue(map);
      await this.setAdditionalLabel(value?.fieldRefName);
    } else {
      this.form.get('value')?.setValue('');
    }
    this.component.value = this.form.get('value')?.value;
  }

  private async setAdditionalLabel(refName: string | undefined | null) {
    const recordTypeId = await firstValueFrom(this.recordTypeId$);
    if (!!refName && refName.includes('.')) {
      const parts = this._dataModelStore.getLabelPartsFromPath(recordTypeId, refName)?.slice(0, -1);
      this._additionalLabel.next(parts?.join(' > '));
    } else {
      this._additionalLabel.next(undefined);
    }
  }

  updateModelFromForm() {
    this.component.operator = this.form.get('operator')?.value;
    if (this.component.type !== FormulaValueType.Field) {
      this.component.value = this.form.get('value')?.value;
    } else {
      //updated when field changes
    }
    this.ref.markForCheck();
    this.componentUpdated.emit(this.component);
  }

  updateFormFromModel() {
    if (this.component.operator) {
      this.form.get('operator')?.setValue(this.component.operator, { emitEvent: false });
    }
    switch (this.component.type) {
      case FormulaValueType.Formula:
        if (this.component.value) {
          this.form.get('value')?.setValue(this.component.value, { emitEvent: false });
        } else {
          this.form.get('value')?.setValue(
            {
              components: [],
            },
            { emitEvent: false },
          );
        }
        break;

      case FormulaValueType.Field:
        if (this.component.value) {
          const field = this.component.value as TableSchemaField;
          this._field.next({
            fieldType: MetadataFieldType.default,
            refName: field?.fieldRefName ?? '',
            recordTypeId: '',
            id: field.fieldId ?? '',
            label: field.fieldLabel ?? '',
          });
          this.form.get('value')?.setValue(field, { emitEvent: false });
        }
        break;

      default:
        this.form.get('value')?.setValue(this.component.value, { emitEvent: false });
        break;
    }
    this.ref.markForCheck();
  }

  onDeleteComponent() {
    this.deleteComponent.emit(this.index);
  }

  dateChanged(event: any) {
    this.form.get('value')?.setValue(getDateOnlyString(event.value));
    this.updateModelFromForm();
  }

  displayFn(field: TableSchemaField): string {
    return field && field.name ? field.name : '';
  }

  getFormulaValue(component: FormulaComponent): Formula {
    return component.value as Formula;
  }

  onFormulaUpdated(formula: Formula) {
    this.component.value = formula;
    this.componentUpdated.emit(this.component);
  }
}
