var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from "../meta-data-decorators";
const recordTypeId = 'RecentlyViewedRecord';
let RecentlyViewedRecord = class RecentlyViewedRecord {
    id;
    userId;
    user;
    recordType;
    recordId;
    ts;
    displayName;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], RecentlyViewedRecord.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'User' })
], RecentlyViewedRecord.prototype, "userId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'User' })
], RecentlyViewedRecord.prototype, "user", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'recordTypeLookup' })
], RecentlyViewedRecord.prototype, "recordType", void 0);
__decorate([
    FieldData({ recordTypeId })
], RecentlyViewedRecord.prototype, "recordId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Timestamp', fieldType: 'datetime' })
], RecentlyViewedRecord.prototype, "ts", void 0);
__decorate([
    FieldData({ recordTypeId })
], RecentlyViewedRecord.prototype, "displayName", void 0);
RecentlyViewedRecord = __decorate([
    RecordTypeData({
        id: recordTypeId,
        usesNewDataModel: true,
        hasBackingTable: true,
        displayNameSingular: 'Recently Viewed Record',
        displayNamePlural: 'Recently Viewed Records',
        dataModelCustomization: {
            hideFromAdmin: true,
            allowCustomFields: false,
        }
    })
], RecentlyViewedRecord);
export { RecentlyViewedRecord };
