import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  catchError,
  combineLatest,
  debounceTime,
  filter,
  map,
  of,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { CustomValidators } from 'app/core/validators/custom-validators';
import { DataModelStoreService } from 'app/core/data-model/services/data-model.store';
import { Icons } from 'app/core/icons/models';
import { IsLoadingService } from '@service-work/is-loading';
import { QuicksearchService } from 'app/core/services/quicksearch.service';
import { ResultItem } from 'portal-commons/dist/search/searchTypes';
import { Router } from '@angular/router';
import { SearchResultComponent } from './search-result/search-result.component';
import { fuseAnimations } from '@fuse/animations/public-api';
import { RecentlyViewedService } from 'app/core/services/recently-viewed.service';
import { RecordLinkService } from 'app/core/services/record-link.service';
import { RecentlyViewedRecord } from 'portal-commons/dist/data-model/record-types/recently-viewed-record';
import { formatDistance, parseISO } from 'date-fns';

@UntilDestroy()
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'search',
  templateUrl: './search.component.html',
  encapsulation: ViewEncapsulation.None,
  exportAs: 'fuseSearch',
  animations: fuseAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent implements OnChanges, OnInit, AfterViewInit {
  quickSearchService = inject(QuicksearchService);
  loadingService = inject(IsLoadingService);
  dm = inject(DataModelStoreService);
  ref = inject(ChangeDetectorRef);
  fb = inject(FormBuilder);
  recentService = inject(RecentlyViewedService);
  recordLinkService = inject(RecordLinkService);
  cd = inject(ChangeDetectorRef);

  @Input() appearance: 'basic' | 'bar' = 'basic';
  @Input() debounce = 300;
  @Input() minLength = 2;
  opened = false;

  form: FormGroup;
  @ViewChild('searchInput') searchInput: ElementRef;

  @ViewChildren(SearchResultComponent)
  results: QueryList<SearchResultComponent>;
  private keyManager: ActiveDescendantKeyManager<SearchResultComponent>;

  SEARCH_KEY = crypto.randomUUID();

  searchResults$: Observable<
    | {
      results: any[];
      label: string;
      id: string;
      limit?: number | undefined;
      recordType?: string | undefined;
      searchPaths?: string[] | undefined;
    }[]
    | undefined
  >;

  recentResults$: Observable<ResultItem[] | undefined> | undefined;

  categorySet: { id: string; description: string }[] = [];

  onKeyUp(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      void this.selectResult(this.keyManager.activeItem?.result);
    } else {
      this.keyManager.onKeydown(event);
    }
  }

  ngAfterViewInit() {
    this.keyManager = new ActiveDescendantKeyManager(this.results).withWrap().withTypeAhead();
  }

  router = inject(Router);
  Icons = Icons;
  inputFocus$ = new BehaviorSubject(false);

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  @HostListener('document:keydown.meta./') searchTrigger() {
    if (this.appearance === 'basic') {
      if (this.searchInput) {
        this.searchInput.nativeElement.focus();
      }
    }
    if (!this.opened) {
      this.open();
    }
  }

  /**
   * Host binding for component classes
   */
  @HostBinding('class') get classList(): any {
    return {
      'search-appearance-bar': this.appearance === 'bar',
      'search-appearance-basic': this.appearance === 'basic',
      'search-opened': this.opened,
    };
  }

  /**
   * Setter for bar search input
   *
   * @param value
   */
  @ViewChild('barSearchInput')
  set barSearchInput(value: ElementRef) {
    // If the value exists, it means that the search input
    // is now in the DOM and we can focus on the input..
    if (value) {
      // Give Angular time to complete the change detection cycle
      setTimeout(() => {
        // Focus to the input element
        value.nativeElement.focus();
      });
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On changes
   *
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    // Appearance
    if ('appearance' in changes) {
      // To prevent any issues, close the
      // search after changing the appearance
      this.close();
    }
  }

  get categoriesArray(): FormArray {
    return this.form.get('categories') as FormArray;
  }

  initCategories() {
    const searchRecTypes = Array.from(this.dm.getRecordTypes()!.values()).filter(
      (f) => f.supports && f.supports.globalSearch,
    );

    for (const searchRecType of searchRecTypes) {
      this.categorySet.push({
        id: searchRecType.alias!,
        description: searchRecType.displayNamePlural,
      });
    }
    this.categorySet.push({ id: 'nav', description: 'Site Navigation' });

    const lastSavedCategories: string[] = localStorage.getItem('search.categories')?.split(',') ?? [];
    const categoryInit: boolean[] = [];
    for (const val of this.categorySet) {
      if (lastSavedCategories.length === 0 || lastSavedCategories.includes(val.id)) { categoryInit.push(true); }
      else { categoryInit.push(false); }
    }

    for (const catVal of categoryInit) {
      this.categoriesArray.push(new FormControl(catVal));
    }
  }

  /**
   * On init
   */
  ngOnInit(): void {
    this.form = this.fb.group({
      input: [''],
      categories: this.fb.array([], [CustomValidators.minSelectedCheckboxes()]),
    });
    this.initCategories();
    this.form
      .get('categories')!
      .valueChanges.pipe(
        startWith(this.form.get('categories')?.value),
        tap((val) => {
          const saveLocal = val.map((ival: boolean, index: number) => {
            if (ival === true) {
              return this.categorySet.at(index)?.id;
            }
            return undefined;
          }).filter((f: string | undefined | null) => f !== undefined && f !== null).join(',');
          localStorage.setItem('search.categories', saveLocal);
        }),
      )
      .subscribe();

    this.searchResults$ = combineLatest([
      this.form
        .get('input')
        .valueChanges.pipe(
          startWith(''),
          debounceTime(this.debounce),
          untilDestroyed(this),
          shareReplay(1),
        ),
      this.form.get('categories').valueChanges.pipe(
        untilDestroyed(this),
        startWith(this.form.get('categories')?.value),
        map((categoryVals: boolean[] | undefined) => {
          const strCats: string[] = [];
          if (categoryVals === undefined) {
            strCats.push('*');
            return strCats;
          }
          for (const [index, val] of categoryVals.entries()) {
            if (val === true) {
              strCats.push(this.categorySet[index].id);
            }
          }
          return strCats;
        }),
        shareReplay(1),
      ),
    ]).pipe(
      switchMap(([filter, categories]) => {
        if (!filter || filter.length < this.minLength || categories?.length === 0) {
          return of(undefined);
        }
        return this.loadingService.add(
          this.quickSearchService.search(filter, categories).pipe(shareReplay(1)),
          { key: this.SEARCH_KEY },
        );
      }),
      map((results) => {
        this.keyManager.setActiveItem(-1);
        return results?.filter((f) => f.results.length > 0);
      }),
      shareReplay(1),
    );

    this.inputFocus$
      .pipe(
        filter((f) => f === false),
        tap(() => {
          this.form.get('input')?.setValue(null);
        }),
      )
      .subscribe();

    this.recentResults$ = this.recentService.recentlyViewed$.pipe(
      untilDestroyed(this),
      catchError((err) => {
        console.error('Unable to fetch recently viewed', err);
        return of(undefined);
      }),
      map((recent) => {
        if (!recent) { return undefined; }
        return recent.map(m => this.getSearchResultFromRecent(m)).filter(f => f !== undefined);
      })
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * On keydown of the search input
   *
   * @param event
   */
  onKeydown(event: KeyboardEvent): void {
    // Listen for escape to close the search
    // if the appearance is 'bar'
    if (this.appearance === 'bar') {
      // Escape
      if (event.code === 'Escape') {
        // Close the search
        this.close();
      }
    } else {
      if (event.code === 'Escape') {
        console.log('click escape');
        if (this.inputFocus$.getValue() === true) {
          this.closeSearch(null);
        }
      }
    }
  }

  /**
   * Open the search
   * Used in 'bar'
   */
  open(): void {
    // Return if it's already opened
    if (this.opened) {
      return;
    }

    // Open the search
    this.opened = true;
  }

  closeSearch(event: any) {
    this.inputFocus$.next(false);
    this.searchInput.nativeElement.blur();
    if (event) {
      event.stopPropagation();
    }
  }

  /**
   * Close the search
   * * Used in 'bar'
   */
  close(): void {
    // Return if it's already closed
    if (!this.opened) {
      return;
    }

    // Clear the search input
    this.form.get('input')?.setValue('');

    // Close the search
    this.opened = false;
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  selectResult(item: ResultItem) {
    if (!item.link && item.id && item.recordType) {
      item.link = this.recordLinkService.getRecordTypeViewLink(item.recordType, item.id);
    }
    if (!item.link) { return; }
    void this.router.navigate([item.link]);
    this.closeSearch(null);
  }

  displayFn(val: any): string {
    if (!val) {
      return '';
    }
    return val.title;
  }

  getSearchResultFromRecent(recent: RecentlyViewedRecord): ResultItem | undefined {
    if (!recent.recordId || !recent.recordType) { return undefined; }
    const recType = this.dm.getRecordType(recent.recordType);
    if (!recType) { return undefined; }
    const subtitleParts: string[] = [];
    if (recent.displayName) { subtitleParts.push(recType.displayNameSingular); }
    subtitleParts.push(`${formatDistance(parseISO(recent.ts!), new Date())} ago`);

    const resultItem: ResultItem = {
      title: recent.displayName ?? recType.displayNameSingular,
      id: recent.recordId,
      recordType: recent.recordType,
      subtitle: subtitleParts.join(' &bull; ')
    };
    return resultItem;
  }
}
