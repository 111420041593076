import { DefaultFinancialBucketLabels } from "../claims/models";
export const recordTypeMetadataCache = new Map();
const fieldMetadataCache = new Map();
export function RecordTypeData(data) {
    return (constructor) => {
        const useRec = data;
        const recType = data.id.toUpperCase();
        const fieldRecType = matchFieldRecordTypeName(recType);
        const useFields = fieldRecType
            ? fieldMetadataCache.get(fieldRecType)
            : undefined;
        useRec.fields = useFields;
        //console.log('settingRecordTypeData', recType, 'fieldCount', data.fields?.length ?? 0);
        recordTypeMetadataCache.set(recType, data);
    };
}
function matchFieldRecordTypeName(name) {
    const recTypes = Array.from(fieldMetadataCache.keys());
    // console.log('matchFieldRecordTypeName', name, recTypes);
    if (recTypes.length === 0) {
        return undefined;
    }
    if (recTypes.filter((r) => r === name).length === 1) {
        return recTypes.find((r) => r === name);
    }
    const matches = recTypes
        .filter((r) => r.startsWith(name))
        .sort((a, b) => b.length - a.length);
    if (matches && matches.length > 0) {
        return matches[0];
    }
    return undefined;
}
export function FieldData(data) {
    return (target, key) => {
        if (!data.recordTypeId) {
            return;
        }
        data.refName ||= key;
        data.fieldType ||= 'string';
        if (data.recordTypeId === 'ClaimFinancialBucket' && !data.label) {
            data.label = DefaultFinancialBucketLabels[data.refName];
        }
        else if (!data.label) {
            data.label = splitCamelCase(key);
        }
        if (!data.refName) {
            data.refName = key;
        }
        if (!data.fieldType) {
            data.fieldType = 'string';
        }
        //console.log('recordTypeField', data.recordTypeId.toUpperCase(), JSON.stringify(data));
        const existing = fieldMetadataCache.get(data.recordTypeId.toUpperCase()) ?? [];
        existing.push(data);
        fieldMetadataCache.set(data.recordTypeId.toUpperCase(), existing);
    };
}
export function splitCamelCase(text) {
    const result = text.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
}
