import { FilterType, PrettyLogicalOperator } from '../data-filters/models';
import { match } from 'ts-pattern';
import { parseISO } from 'date-fns';
export var FieldType;
(function (FieldType) {
    FieldType["Boolean"] = "Boolean";
    FieldType["Date"] = "Date";
    FieldType["DateTime"] = "DateTime";
    FieldType["Integer"] = "Integer";
    FieldType["Number"] = "Number";
    FieldType["String"] = "String";
})(FieldType || (FieldType = {}));
export var ValueType;
(function (ValueType) {
    ValueType["Single"] = "Single";
    ValueType["Multiple"] = "Multiple";
})(ValueType || (ValueType = {}));
export var SortOrder;
(function (SortOrder) {
    SortOrder["asc"] = "asc";
    SortOrder["desc"] = "desc";
})(SortOrder || (SortOrder = {}));
export var FieldSource;
(function (FieldSource) {
    FieldSource["IndexField"] = "IndexField";
    FieldSource["CalculatedField"] = "CalculatedField";
})(FieldSource || (FieldSource = {}));
export function isFilterValid(filter) {
    //console.log('isFilterValid', filter);
    return (filter &&
        (filter.groups ?? [])
            .map((group) => isFilterValid(group))
            .concat((filter.conditions ?? []).map((condition) => condition && isFilterConditionValid(condition)))
            .filter((isValid) => !isValid).length === 0);
}
export function isFilterEmpty(filter) {
    return (!filter ||
        (filter && (filter.groups?.length ?? 0) === 0 && (filter.conditions?.length ?? 0) === 0));
}
export function isFilterConditionValid(condition) {
    //console.log('isFilterConditionValid', condition);
    if (!condition) {
        return false;
    }
    //todo: can we just have one?
    if (!condition.fieldRefName && !condition.mappedObject) {
        return false;
    }
    if (!condition.filter) {
        return false;
    }
    const requiredFields = FilterTypeConditionRequiredProperties[condition.filter];
    return (requiredFields
        .map((field) => !!condition[field] && condition[field] !== '')
        .filter((result) => !result).length === 0);
}
export function isFilterConditionPropertyRequired(filterType, property) {
    if (!filterType) {
        return false;
    }
    const requiredProperties = FilterTypeConditionRequiredProperties[filterType];
    return !requiredProperties.length || requiredProperties.includes(property);
}
export const FilterTypeConditionRequiredProperties = {
    After: ['searchParameter1'],
    AtorAfter: ['searchParameter1'],
    AtorBefore: ['searchParameter1'],
    Before: ['searchParameter1'],
    Between: ['searchParameter1', 'searchParameter2'],
    Contains: ['searchParameter1'],
    ContainsAll: ['searchParameter1'],
    ContainsAny: ['searchParameter1'],
    DoesnotContain: ['searchParameter1'],
    EndsWith: ['searchParameter1'],
    Equals: ['searchParameter1'],
    EqualsCaseInsensitive: ['searchParameter1'],
    IntheCurrent: ['searchParameter1Period'],
    IntheLast: ['searchParameter1', 'searchParameter1Period'],
    IntheNext: ['searchParameter1', 'searchParameter1Period'],
    IsEmpty: [],
    IsNotEmpty: [],
    IsNull: [],
    IsNotNull: [],
    LessThan: ['searchParameter1'],
    MoreThan: ['searchParameter1'],
    NotEqual: ['searchParameter1'],
    StartsWith: ['searchParameter1'],
    InList: ['searchParameter1'],
    NotInList: ['searchParameter1'],
};
export var FieldFormat;
(function (FieldFormat) {
    FieldFormat["csv"] = "csv";
    FieldFormat["currency"] = "currency";
    FieldFormat["currencyInteger"] = "currencyInteger";
    FieldFormat["date"] = "date";
    FieldFormat["dateTime"] = "dateTime";
    FieldFormat["double"] = "double";
    FieldFormat["email"] = "email";
    FieldFormat["float"] = "float";
    FieldFormat["imagePreview"] = "imagePreview";
    FieldFormat["sensitiveData"] = "sensitiveData";
    FieldFormat["sensitiveDataLast4"] = "sensitiveDataLast4";
    FieldFormat["uri"] = "uri";
    FieldFormat["percentage"] = "percentage";
    FieldFormat["percentageWholeNumber"] = "percentageWholeNumber";
})(FieldFormat || (FieldFormat = {}));
export function getPathsFromFilterGroup(filter) {
    if (!filter) {
        return [];
    }
    const groupPaths = (filter.groups ?? []).flatMap((x) => getPathsFromFilterGroup(x));
    return (filter.conditions ?? [])
        .map((x) => x.fieldRefName)
        .concat(groupPaths)
        .filter((x) => !!x);
}
export function doesModelSatisfyFilter(filter, model) {
    if (!filter) {
        throw new Error(`Missing filter to check model against`);
    }
    if (!model) {
        throw new Error(`Missing model to check filter against`);
    }
    const conditionResults = filter.conditions.map((c) => doesModelSatisfyFilterCondition(c, model));
    const groupResults = filter.groups.map((g) => doesModelSatisfyFilter(g, model));
    return filter.operator === PrettyLogicalOperator.All
        ? conditionResults.includes(false) || groupResults.includes(false)
        : conditionResults.includes(true) || groupResults.includes(true);
}
function parseDate(input) {
    try {
        if (input instanceof Date) {
            return new Date(input.toDateString());
        }
        if (typeof input === 'number') {
            return new Date(input);
        }
        return parseISO(input.split('T')[0]);
    }
    catch (err) {
        console.error(`Unable to getDateFromString ${input}`, err);
        throw new Error(`Unable to parse date from input: ${input}`);
    }
}
function doesModelSatisfyFilterCondition(condition, model) {
    const path = condition.mappedObject ?? condition.fieldRefName ?? condition.fieldId;
    const val = model[`${path}`];
    try {
        const result = match(condition.filter)
            .with(FilterType.After, () => val > parseDate(`${condition.searchParameter1}`))
            .with(FilterType.AtorAfter, () => val >= parseDate(`${condition.searchParameter1}`))
            .with(FilterType.AtorBefore, () => val <= parseDate(`${condition.searchParameter1}`))
            .with(FilterType.Before, () => val < parseDate(`${condition.searchParameter1}`))
            .with(FilterType.Between, () => {
            const lower = parseDate(`${condition.searchParameter1}`);
            const upper = parseDate(condition.searchParameter2);
            const dVal = parseDate(val);
            return lower <= dVal && dVal <= upper;
        })
            .with(FilterType.Contains, () => val.includes(condition.searchParameter1))
            .with(FilterType.ContainsAll, () => {
            if (!condition.searchParameter1 || !Array.isArray(condition.searchParameter1)) {
                return false;
            }
            if (!val || !Array.isArray(val)) {
                return false;
            }
            const needles = condition.searchParameter1;
            return needles.every((i) => val.includes(i));
        })
            .with(FilterType.ContainsAny, () => {
            if (!condition.searchParameter1 || !Array.isArray(condition.searchParameter1)) {
                return false;
            }
            if (!val || !Array.isArray(val)) {
                return false;
            }
            const needles = condition.searchParameter1;
            return needles.some((i) => val.includes(i));
        })
            .with(FilterType.DoesnotContain, () => !val.includes(condition.searchParameter1))
            .with(FilterType.EndsWith, () => val.endsWith(condition.searchParameter1))
            .with(FilterType.Equals, () => `${val}` === `${condition.searchParameter1}`)
            .with(FilterType.EqualsCaseInsensitive, () => `${val}`.toUpperCase() === `${condition.searchParameter1}`.toUpperCase())
            .with(FilterType.IntheCurrent, () => false)
            .with(FilterType.IntheLast, () => false)
            .with(FilterType.IntheNext, () => false)
            .with(FilterType.IsEmpty, () => `${val}`.length === 0)
            .with(FilterType.IsNotEmpty, () => `${val}`.length > 0)
            .with(FilterType.IsNull, () => val === null)
            .with(FilterType.IsNotNull, () => val !== null)
            .with(FilterType.LessThan, () => val < parseInt(`${condition.searchParameter1}`))
            .with(FilterType.MoreThan, () => val > parseInt(`${condition.searchParameter1}`))
            .with(FilterType.NotEqual, () => val !== condition.searchParameter1)
            .with(FilterType.StartsWith, () => val.startsWith(condition.searchParameter1))
            .with(FilterType.InList, () => `${condition.searchParameter1}`.split(',').includes(val))
            .with(FilterType.NotInList, () => !`${condition.searchParameter1}`.split(',').includes(val))
            .exhaustive();
        console.log(`filter condition eval`, condition, path, val, result);
        return result ?? false;
    }
    catch (err) {
        console.log(`Error evaluatling filter condition`, err, condition);
        return false;
    }
}
