<ng-container [formGroup]="this.form">
  <div
    class="flex gap-2 w-full flex-wrap"
    [ngClass]="{
      'flex-row': this.orientation === 'horizontal',
      'flex-col': this.orientation === 'vertical'
    }">
    <tb-datamodel-field-treeselect
      restrictedArea="Filters"
      [rootRecordTypeId$]="recordType$"
      (fieldchanged)="fieldChanged($event)"
      (inputmatched)="inputMatched($event)"
      [additionalFieldClasses]="autoCompleteFieldClasses"
      [isRequired]="true"
      [inputValue$]="this.conditionField$"
      [rootFieldsOnly]="this.rootFieldsOnly"
      [includeListFields]="this.includeListFields">
    </tb-datamodel-field-treeselect>
    <mat-form-field class="fuse-mat-dense min-w-48 no-margin-bottom">
      <mat-select matInput placeholder="Operator" [formControlName]="'filterType'">
        <mat-option *ngFor="let option of filterOptions" [value]="option.value">{{
          option.text
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <ng-container *ngIf="this.valueTemplate$ | async">
      <ng-container *ngTemplateOutlet="this.valueTemplate$ | async"> </ng-container>
    </ng-container>
  </div>
</ng-container>
<ng-template #dateValue [formGroup]="this.form">
  <tb-relative-date-picker formControlName="searchParameter1"></tb-relative-date-picker>
</ng-template>

<ng-template #textValue [formGroup]="this.form">
  <mat-form-field class="fuse-mat-dense no-margin-bottom flex-grow">
    <input
      class="flex-grow"
      matInput
      [formControlName]="'searchParameter1'"
      placeholder="Enter Value..." />
  </mat-form-field>
</ng-template>

<ng-template #booleanValue [formGroup]="this.form">
  <mat-form-field class="fuse-mat-dense no-margin-bottom flex-grow">
    <mat-select matInput [formControlName]="'searchParameter1'" class="flex-grow">
      <mat-option value="true">True</mat-option>
      <mat-option value="false">False</mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template #relativeDateValue [formGroup]="this.form">
  <mat-form-field class="fuse-mat-dense no-margin-bottom w-40">
    <input matInput [maxlength]="6" [formControlName]="'searchParameter1'" placeholder="3" />
  </mat-form-field>
  <mat-form-field
    class="fuse-mat-dense no-margin-bottom flex-grow"
    *ngIf="this.timePeriods$ | async as timePeriods">
    <mat-select matInput class="flex-grow" [formControlName]="'searchParameter1Period'">
      <mat-option *ngFor="let period of timePeriods" [value]="period.size">{{
        period.label
      }}</mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template #toDateValue [formGroup]="this.form">
  <mat-form-field
    class="fuse-mat-dense no-margin-bottom flex-grow"
    *ngIf="this.timePeriods$ | async as timePeriods">
    <mat-select matInput class="flex-grow" [formControlName]="'searchParameter1Period'">
      <mat-option *ngFor="let period of timePeriods" [value]="period.size">{{
        period.label
      }}</mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template #noValue> </ng-template>

<ng-template #betweenDatesValue [formGroup]="this.form">
  <tb-relative-date-picker formControlName="searchParameter1"></tb-relative-date-picker>
  <tb-relative-date-picker formControlName="searchParameter2"></tb-relative-date-picker>
</ng-template>

<ng-template #codedListValue [formGroup]="this.form">
  <mat-form-field class="fuse-mat-dense no-margin-bottom flex-grow">
    <mat-select
      [required]="true"
      swSm
      swLeft
      formControlName="searchParameter1"
      [swIsLoading]="this.LOADING_KEY">
      <mat-option *ngFor="let code of codes$ | async" [value]="code.code">{{
        code.description
      }}</mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template #codedListMultipleValue [formGroup]="this.form">
  <mat-form-field class="fuse-mat-dense no-margin-bottom flex-grow">
    <mat-select [required]="true" [multiple]="true" formControlName="searchParameter1">
      <mat-option *ngFor="let code of codes$ | async" [value]="code.code">{{
        code.description
      }}</mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template #codeEnumValue [formGroup]="this.form">
  <mat-form-field class="fuse-mat-dense no-margin-bottom flex-grow">
    <mat-select [required]="true" formControlName="searchParameter1">
      <mat-option *ngFor="let item of this.enumCodes$ | async" [value]="item.id">
        {{ item.displayName }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>
