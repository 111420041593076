import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BasicFieldTypes, ModelExpressionType } from 'portal-commons/dist/data-model/record-types';
import { BehaviorSubject, firstValueFrom, Observable, tap } from 'rxjs';
import { RecordTypeFieldMetadata } from 'app/core/data-model/models/models';
import {
  Formula,
  FormulaComponent as FormulaComponentModel,
  FormulaValueType,
  FormulaAggregateFunction,
  getFormulaDisplay,
} from 'portal-commons/dist/data-model/formula';

@UntilDestroy()
@Component({
  selector: 'tb-formula',
  templateUrl: './formula.component.html',
  styles: [
    /* language=SCSS */
    `
      :host ::ng-deep {
        .formula-error {
          //text-red-400
          --tw-text-opacity: 1 !important;
          color: rgb(248 113 113 / var(--tw-text-opacity)) /* #f87171 */ !important;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormulaComponent implements OnInit {
  @Input() recordTypeId$!: Observable<string>;
  @Input() rootFieldsOnly = false;
  @Input() includeListFields = false;
  @Input() outputFormat$!: Observable<BasicFieldTypes>;
  @Input() formula!: Formula;
  @Input() index!: number;
  @Input() depth!: number;
  @Output() deleteGroup = new EventEmitter<number>();
  @Output() formulaUpdated = new EventEmitter<Formula>();

  form!: FormGroup;
  ModelExpressionType = ModelExpressionType;
  constructor(private ref: ChangeDetectorRef, private formBuilder: FormBuilder) {}

  _field = new BehaviorSubject<RecordTypeFieldMetadata | undefined>(undefined);
  field$ = this._field.asObservable();

  FormulaValueType = FormulaValueType;

  private _allowOperators = new BehaviorSubject<boolean>(true);
  allowOperators$ = this._allowOperators.asObservable();

  functions = [
    { text: 'Minimum', value: FormulaAggregateFunction.Min },
    { text: 'Maximum', value: FormulaAggregateFunction.Max },
    { text: 'Average', value: FormulaAggregateFunction.Avg },
    { text: 'Sum', value: FormulaAggregateFunction.Sum },
  ];

  getFormulaDisplay = getFormulaDisplay;

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      aggregateFunction: [],
      components: this.formBuilder.array([]),
    });

    this.form
      .get('aggregateFunction')
      ?.valueChanges.pipe(
        tap((x) => {
          this.formula.aggregateFunction = this.form.get('aggregateFunction')?.value;
          console.log(`allow operators?!`, !this.formula.aggregateFunction);
          this._allowOperators.next(!this.formula.aggregateFunction);
          this.formulaUpdated.emit(this.formula);
          this.ref.markForCheck();
        }),
      )
      .subscribe();

    this._allowOperators.next(!this.formula.aggregateFunction);
  }

  ngAfterViewInit() {
    this.updateFormFromModel();
  }

  updateFormFromModel() {
    this.form
      .get('aggregateFunction')
      ?.setValue(this.formula.aggregateFunction, { emitEvent: false });
    this.componentsArray.clear();
    for (const component of this.formula.components) {
      this.componentsArray.push(
        this.formBuilder.group(
          {
            operator: component.operator,
            type: component.type,
            value: component.value,
          },
          { emitEvent: false },
        ),
      );
    }
    this.ref.markForCheck();
  }

  get componentsArray(): FormArray {
    return this.form.get('components') as FormArray;
  }

  async addValueComponent() {
    switch (await firstValueFrom(this.outputFormat$)) {
      case 'date':
        this.addDateComponent();
        break;
      case 'decimal':
      case 'integer':
      case 'currency':
        this.addNumberComponent();
        break;
      default:
        this.addTextComponent();
        break;
    }
  }

  addFormulaComponent() {
    if (this.formula) {
      this.formula.components.push({
        type: FormulaValueType.Formula,
        value: { components: [] } as Formula,
      });
      this.ref.markForCheck();
      this.formulaUpdated.emit(this.formula);
    }
  }

  addDateComponent() {
    if (this.formula) {
      this.formula.components.push({
        type: FormulaValueType.Date,
      });
      this.ref.markForCheck();
      this.formulaUpdated.emit(this.formula);
    }
  }

  addFieldComponent() {
    if (this.formula) {
      this.formula.components.push({
        type: FormulaValueType.Field,
      });
      this.ref.markForCheck();
      this.formulaUpdated.emit(this.formula);
    }
  }

  addTextComponent() {
    if (this.formula) {
      this.formula.components.push({
        type: FormulaValueType.Text,
      });
      this.ref.markForCheck();
      this.formulaUpdated.emit(this.formula);
    }
  }

  addNumberComponent() {
    if (this.formula) {
      this.formula.components.push({
        type: FormulaValueType.Number,
      });
      this.ref.markForCheck();
      this.formulaUpdated.emit(this.formula);
    }
  }

  removeComponent(index: number) {
    if (this.formula) {
      if (this.formula.components.length < index + 1) {
        return;
      }
      const newModel = [...this.formula.components];
      newModel.splice(index, 1);
      this.formula.components = newModel;
      this.ref.markForCheck();
      this.formulaUpdated.emit(this.formula);
    }
  }

  onComponentUpdated(component: FormulaComponentModel) {
    this.formulaUpdated.emit(this.formula);
  }
}
