//https://medium.com/ngx/3-ways-to-implement-conditional-validation-of-reactive-forms-c59ed6fc3325

import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { toNumber } from '../utils/number-helper';
import { getDateOnlyString } from '../utils/form-helper';

export interface BooleanFn {
  (): boolean;
}
export class CustomValidators {
  static conditionalValidator(
    predicate: BooleanFn,
    validator: ValidatorFn,
    errorNamespace?: string,
  ): ValidatorFn {
    return (formControl) => {
      if (!formControl.parent) {
        return null;
      }
      let error = null;
      if (predicate()) {
        error = validator(formControl);
      }
      if (errorNamespace && error) {
        const customError = {};
        customError[errorNamespace] = error;
        error = customError;
      }
      return error;
    };
  }

  static emailListValidator(): ValidatorFn {
    const regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    const errorObj = { emailList: true };
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }
      const results = control.value.replace(/\s/g, "").split(/,|;/);
      for (const result of results) {
        if (!result) { return errorObj; }
        if (!regex.test(result)) {
          return errorObj;
        }
      }
      return null;
    };
  }

  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);
      //console.log('pattern', regex, control.value, valid);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }

  static excludePatternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);
      //console.log('excl-pattern', regex, control.value, valid);

      // if false, return no error (no error), else return error passed in the second parameter
      return !valid ? null : error;
    };
  }

  static defaultErrorName(errorTag: string) {
    const validator: ValidatorFn = (formArray: AbstractControl) => {
      console.log('defaultErrorName', errorTag);
      return { [errorTag]: true };
    };
    return validator;
  }

  static requireAutocompleteMatch(control: AbstractControl) {
    const selection: any = control.value;
    if (typeof selection === 'string' && selection !== '') {
      return { autoCompleteValueMustMatch: true };
    }
    return null;
  }

  /**
   * Min validation that can be used on inputs that are type text as well as number
   * @param min 
   * @returns 
   */
  static min(min: number) {
    const validator: ValidatorFn = (control: AbstractControl) => {
      const selection: any = control.value;
      if (typeof selection === 'string' && selection !== '') {
        const numVal = toNumber(selection);
        if (numVal !== undefined && numVal !== null) {
          if (numVal < min) {
            return { min: { min: min, actual: numVal } };
          }
        }
      }
      if (typeof selection === 'number') {
        if (selection < min) {
          return { min: { min: min, actual: selection } };
        }
      }
      return null;
    };
    return validator;
  }

  /**
   * Max validation that can be used on inputs that are type text as well as number
   * @param max 
   * @returns
   */
  static max(max: number) {
    const validator: ValidatorFn = (control: AbstractControl) => {
      const selection: any = control.value;
      if (typeof selection === 'string' && selection !== '') {
        const numVal = toNumber(selection);
        if (numVal !== undefined && numVal !== null) {
          if (numVal > max) {
            return { max: { max: max, actual: numVal } };
          }
        }
      }
      if (typeof selection === 'number') {
        if (selection > max) {
          return { max: { max: max, actual: selection } };
        }
      }
      return null;
    };
    return validator;
  }

  /**
     * Max validation that can be used on inputs that are type text as well as number
     * @param max 
     * @returns
     */
  static dateOnOrAfter(compareDate: string | Date | null | undefined) {
    const validator: ValidatorFn = (control: AbstractControl) => {
      const selection: any = control.value;
      const selectionVal = typeof selection === 'string' ? selection : getDateOnlyString(selection);
      const compareVal = typeof compareDate === 'string' ? compareDate : getDateOnlyString(compareDate);
      if (!selectionVal || !compareVal) { return null; }
      if (selectionVal < compareVal) {
        return { dateOnOrAfter: { compareDate: compareDate, actual: selection } };
      }
      return null;
    };
    return validator;
  }


  static alwaysThrowValidator(error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      return error;
    };
  }

  //https://stackoverflow.com/a/72594000
  static minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: AbstractControl) => {
      if (formArray instanceof FormArray) {
        const totalSelected = formArray.controls
          .map((control) => control.value)
          .reduce((prev, next) => (next ? prev + next : prev), 0);
        return totalSelected >= min ? null : { required: true };
      }

      throw new Error('formArray is not an instance of FormArray');
    };

    return validator;
  }

  static minSelectedCheckboxesFormGroup(min = 1, property: string) {
    const validator: ValidatorFn = (formArray: AbstractControl) => {
      if (formArray instanceof FormArray) {
        const totalSelected = formArray.controls
          .map((control) => (control as FormGroup).get(property)?.value)
          .reduce((prev, next) => (next ? prev + next : prev), 0);
        return totalSelected >= min ? null : { required: true };
      }

      throw new Error('formArray is not an instance of FormArray');
    };

    return validator;
  }

  static atLeast(min: number) {
    const validator: ValidatorFn = (formArray: AbstractControl) => {
      if (formArray instanceof FormArray) {
        return formArray?.length >= min ? null : { atLeast: { limit: min, actual: formArray.length } };
      }
      if (formArray instanceof FormControl) {
        if (Array.isArray(formArray.value)) {
          return formArray.value.length >= min ? null : { atLeast: { limit: min, actual: formArray.value.length } };
        }
      }
      throw new Error('formArray is not an instance of FormArray');
    };

    return validator;
  }
}
