import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  debounceTime,
  filter,
  map,
  merge,
  of,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AuthService } from 'app/core/auth/auth.service';
import { CustomValidators } from 'app/core/validators/custom-validators';
import { FloatLabelType } from '@angular/material/form-field';
import { IsLoadingService } from '@service-work/is-loading';
import { getObjValueFromPath } from 'app/core/utils/js-helper';
import { injectNgControl } from 'app/shared/directives/noop-value-accessor.directive';
import { ErrorMessagePipe } from 'app/shared/pipes/error-message.pipe';

@UntilDestroy()
@Component({
  selector: 'tb-autocomplete',
  templateUrl: './tb-autocomplete.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class TbAutocompleteComponent implements OnInit, AfterViewInit {
  @Input() floatLabel: FloatLabelType = 'always';
  @Input() denseField = true;
  @Input() label!: string;
  @Input() labelClass: string | undefined;
  @Input() readonly = false;
  @Input() autoFocus = false;
  @Input() matFormFieldClasses: string | undefined = undefined;
  @Input() placeHolder: string | undefined;
  @Input() displayItemProperty = 'description';
  @Input() errorTypes: { [key: string]: string } = {};
  @Input() displayItemFn?: (item: any) => string;
  @Input() filterFn?: (items: any[]) => any[];
  @Input() displayTemplate?: TemplateRef<any>;
  @Input() hint: string;
  @Input() set source(
    value:
      | ((
        filter: string,
        extraParams?: {
          [key: string]: string | number | boolean | (string | number | boolean)[];
        },
      ) => Observable<any[] | undefined>)
      | any[],
  ) {
    console.log('set Source', typeof value === 'function', value instanceof Array);
    if (typeof value === 'function') {
      this.service = value;
    } else if (value instanceof Array) {
      this.providedItems = value.slice(0);
    }
  }
  @Input() extraParams:
    | {
      [key: string]: string | number | boolean | (string | number | boolean)[];
    }
    | undefined = undefined;
  @Output() removeEvent = new EventEmitter<void>();

  triggerSearch$ = new Subject<boolean>();
  LOADING_KEY = crypto.randomUUID();
  ngControl = injectNgControl();
  loadingService = inject(IsLoadingService);
  changeRef = inject(ChangeDetectorRef);
  _authService = inject(AuthService);
  errorPipe = inject(ErrorMessagePipe);
  private providedItems?: any[];
  private service?: (
    filter: string,
    extraParams?: {
      [key: string]: string | number | boolean | (string | number | boolean)[];
    },
  ) => Observable<any[] | undefined>;
  filteredItems$!: Observable<any[] | undefined>;
  errorMessage$ = new BehaviorSubject<string | null>(null);

  @ViewChild('inputBox') firstItem: ElementRef;

  ngOnInit(): void {
    if (this.placeHolder === undefined) {
      this.placeHolder = 'Type to filter results...';
    }
    this.changeRef.markForCheck();

    if (!this.ngControl.control.hasValidator(CustomValidators.requireAutocompleteMatch)) {
      this.ngControl.control.addValidators(CustomValidators.requireAutocompleteMatch);
    }

    this.filteredItems$ = merge(
      this.triggerSearch$.pipe(
        untilDestroyed(this),
        filter(f => !!!this.ngControl.value),
      ),
      this.ngControl.valueChanges!.pipe(
        untilDestroyed(this),
        debounceTime(350),
        filter((f) => typeof f !== 'object')
      )
    ).pipe(
      switchMap((event) => {
        if (typeof event === 'boolean') {
          const root = this.extraParams
            ? this.service!('_SEARCH_', this.extraParams)
            : this.service!('_SEARCH_');

          return this.loadingService.add(
            root.pipe(
              catchError((err: any) => {
                console.error('Failure in autocomplete', err);
                this.errorMessage$.next(this.errorPipe.transform(err));
                return of(undefined);
              }),
              tap(() => this.errorMessage$.next(null)),
              shareReplay(1),
            ),
            { key: this.LOADING_KEY },
          );
        }
        if (this.providedItems) {
          return of(
            this.providedItems.filter((f) =>
              this.displayPropertyStartsWith(f, this.displayItemProperty, event),
            ),
          );
        }

        const root = this.extraParams
          ? this.service!(event, this.extraParams)
          : this.service!(event);

        return this.loadingService.add(
          root.pipe(
            catchError((err: any) => {
              console.error('Failure in autocomoplete', err);
              if (err.errorMessage) {
                this.errorMessage$.next(err.errorMessage);
              }
              return of(undefined);
            }),
            tap(() => this.errorMessage$.next(null)),
            shareReplay(1),
          ),
          { key: this.LOADING_KEY },
        );
      }),
      map((items: any[] | undefined) => {
        if (!items) {
          return [];
        }
        if (this.filterFn !== undefined) {
          return this.filterFn(items);
        }
        return items;
      }),
      shareReplay(1)
    );

    this.showStatus();
  }

  displayPropertyStartsWith(model: any, path: string, matchTo: string): boolean {
    if (!!!matchTo) {
      return false;
    }
    const val = getObjValueFromPath(model, path);
    if (typeof val !== 'string') {
      return false;
    }
    if (!val) {
      return false;
    }
    if (matchTo.length === 1) {
      return val.toLowerCase().startsWith(matchTo.toLowerCase());
    }
    return val.toLowerCase().includes(matchTo.toLowerCase());
  }

  ngAfterViewInit(): void {
    if (this.autoFocus && this.firstItem) {
      this.firstItem.nativeElement.focus({
        preventScroll: true,
      });
    }
  }

  public showStatus() {
    return (
      this._authService.isAgencyUser ||
      this._authService.isTenantUser ||
      this._authService.isTrailblazerUser
    );
  }

  public displayItem(item: any): string | null {
    if (this.displayItemFn) {
      return this.displayItemFn(item);
    }
    if (!!this.displayItemProperty && item) {
      return getObjValueFromPath(item, this.displayItemProperty);
    }
    return null;
  }

  public displayWith = (value: { [x: string]: any; name: any }) => {
    if (this.displayItemFn) {
      return this.displayItemFn(value);
    }
    if (!!this.displayItemProperty && value) {
      return getObjValueFromPath(value, this.displayItemProperty);
    }
    return null;
  };

  onClick() {
    this.removeEvent.emit();
    this.ngControl.control.setValue('');
  }

  triggerSearch() {
    this.triggerSearch$.next(true);
  }
}
