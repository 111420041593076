var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { CommissionBasisType } from '../../commissions/models';
import { FilterType } from '../../data-filters/models';
const recordTypeId = 'CommissionPayment';
let CommissionPayment = class CommissionPayment {
    id;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
    agentId;
    agent;
    agencyId;
    agency;
    invoiceId;
    invoice;
    invoiceLineItemId;
    invoiceLineItem;
    policyPaymentId;
    policyPayment;
    policyId;
    policy;
    payableAmount;
    commissionDate;
    effectiveDate;
    expirationDate;
    basis;
    applied_rate;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], CommissionPayment.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], CommissionPayment.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], CommissionPayment.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], CommissionPayment.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Created By',
        fieldType: 'User',
        jsonStorage: true,
    })
], CommissionPayment.prototype, "createUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Created On',
        fieldType: 'datetime',
    })
], CommissionPayment.prototype, "createdUTC", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Modified By',
        fieldType: 'User',
        jsonStorage: true,
    })
], CommissionPayment.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Modified On',
        fieldType: 'datetime',
    })
], CommissionPayment.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Deleted By',
        fieldType: 'User',
        jsonStorage: true,
    })
], CommissionPayment.prototype, "deleteUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Deleted On',
        fieldType: 'datetime',
    })
], CommissionPayment.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Agent' })
], CommissionPayment.prototype, "agentId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Agent' })
], CommissionPayment.prototype, "agent", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Agency' })
], CommissionPayment.prototype, "agencyId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Agent' })
], CommissionPayment.prototype, "agency", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Invoice' })
], CommissionPayment.prototype, "invoiceId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Invoice' })
], CommissionPayment.prototype, "invoice", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'InvoiceLine' })
], CommissionPayment.prototype, "invoiceLineItemId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'InvoiceLine' })
], CommissionPayment.prototype, "invoiceLineItem", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'PolicyPayment' })
], CommissionPayment.prototype, "policyPaymentId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'PolicyPayment' })
], CommissionPayment.prototype, "policyPayment", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Policy' })
], CommissionPayment.prototype, "policyId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Policy' })
], CommissionPayment.prototype, "policy", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'decimal' })
], CommissionPayment.prototype, "payableAmount", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Commission Date', fieldType: 'date' })
], CommissionPayment.prototype, "commissionDate", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Effective Date', fieldType: 'date' })
], CommissionPayment.prototype, "effectiveDate", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Expiration Date', fieldType: 'date' })
], CommissionPayment.prototype, "expirationDate", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: false, codeEnum: CommissionBasisType })
], CommissionPayment.prototype, "basis", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'decimal' })
], CommissionPayment.prototype, "applied_rate", void 0);
CommissionPayment = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Commission Payment',
        displayNamePlural: 'Commission Payments',
        dataModelCustomization: {
            allowCustomFields: true,
        },
        supports: {
            views: true,
        },
        usesNewDataModel: true,
        workflow: { enabled: true, generalEvents: true },
        quickFilterFields: [
            { recordType: recordTypeId, fieldPath: 'policy.policyNumber', filterType: FilterType.Equals },
            { recordType: recordTypeId, fieldPath: 'agent.name', filterType: FilterType.Contains },
            { recordType: recordTypeId, fieldPath: 'basis', filterType: FilterType.Equals }
        ]
    })
], CommissionPayment);
export { CommissionPayment };
