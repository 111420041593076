import { BasicFieldTypes } from 'portal-commons/dist/data-model/record-types';
import { FieldType, ValueType } from 'portal-commons/dist/views/models';
import { FilterType } from 'portal-commons/dist/data-filters/models';

export interface FilterTypeOption {
    text: string;
    value: string;
    valueType: ValueType;
    allowedTypes: FieldType[];
    allowedDataModelTypes: (BasicFieldTypes | '*')[];
    FilterDisplay: FilterDisplayType;
    hidden?: boolean;
}

enum FilterDisplayType {
    SingleField = 'SingleField',
    BetweenFields = 'BetweenFields',
    NoField = 'NoField',
}

export const filterTypeOptions: FilterTypeOption[] = [
    {
        text: 'Starts With',
        value: FilterType.StartsWith,
        valueType: ValueType.Single,
        allowedTypes: [FieldType.String],
        allowedDataModelTypes: ['string'],
        FilterDisplay: FilterDisplayType.SingleField,
    },
    {
        text: 'Ends With',
        value: FilterType.EndsWith,
        valueType: ValueType.Single,
        allowedTypes: [FieldType.String],
        allowedDataModelTypes: ['string'],
        FilterDisplay: FilterDisplayType.SingleField,
    },
    {
        text: 'Contains',
        value: FilterType.Contains,
        valueType: ValueType.Single,
        allowedTypes: [FieldType.String],
        allowedDataModelTypes: ['string'],
        FilterDisplay: FilterDisplayType.SingleField,
    },
    {
        text: 'Contains Any',
        value: FilterType.ContainsAny,
        valueType: ValueType.Multiple,
        allowedTypes: [FieldType.String],
        allowedDataModelTypes: ['codelist', 'string'],
        FilterDisplay: FilterDisplayType.SingleField,
    },
    {
        text: 'Contains All',
        value: FilterType.ContainsAll,
        valueType: ValueType.Multiple,
        allowedTypes: [FieldType.String],
        allowedDataModelTypes: ['codelist', 'string'],
        FilterDisplay: FilterDisplayType.SingleField,
    },
    {
        text: 'Does Not Contain',
        value: FilterType.DoesnotContain,
        valueType: ValueType.Single,
        allowedTypes: [FieldType.String],
        allowedDataModelTypes: ['string'],
        FilterDisplay: FilterDisplayType.SingleField,
    },
    {
        text: 'Equals',
        value: FilterType.Equals,
        valueType: ValueType.Single,
        allowedTypes: [
            FieldType.String,
            FieldType.Boolean,
            FieldType.Date,
            FieldType.DateTime,
            FieldType.Integer,
            FieldType.Number,
        ],
        allowedDataModelTypes: ['*'],
        FilterDisplay: FilterDisplayType.SingleField,
    },
    {
        text: 'Equals',
        value: FilterType.EqualsCaseInsensitive,
        valueType: ValueType.Single,
        allowedTypes: [
            FieldType.String
        ],
        allowedDataModelTypes: ['*'],
        FilterDisplay: FilterDisplayType.SingleField,
        hidden: true
    },
    {
        text: 'Not Equal',
        value: FilterType.NotEqual,
        valueType: ValueType.Single,
        allowedTypes: [
            FieldType.String,
            FieldType.Boolean,
            FieldType.Date,
            FieldType.DateTime,
            FieldType.Integer,
            FieldType.Number,
        ],
        allowedDataModelTypes: ['*'],
        FilterDisplay: FilterDisplayType.SingleField,
    },
    {
        text: 'Before',
        value: FilterType.Before,
        valueType: ValueType.Single,
        allowedTypes: [FieldType.DateTime, FieldType.Date],
        allowedDataModelTypes: ['date', 'datetime'],
        FilterDisplay: FilterDisplayType.SingleField,
    },
    {
        text: 'At or Before',
        value: FilterType.AtorBefore,
        valueType: ValueType.Single,
        allowedTypes: [FieldType.DateTime, FieldType.Date],
        allowedDataModelTypes: ['date', 'datetime'],
        FilterDisplay: FilterDisplayType.SingleField,
    },
    {
        text: 'After',
        value: FilterType.After,
        valueType: ValueType.Single,
        allowedTypes: [FieldType.DateTime, FieldType.Date],
        allowedDataModelTypes: ['date', 'datetime'],
        FilterDisplay: FilterDisplayType.SingleField,
    },
    {
        text: 'At or After',
        value: FilterType.AtorAfter,
        valueType: ValueType.Single,
        allowedTypes: [FieldType.DateTime, FieldType.Date],
        allowedDataModelTypes: ['date', 'datetime'],
        FilterDisplay: FilterDisplayType.SingleField,
    },
    {
        text: 'Between',
        value: FilterType.Between,
        valueType: ValueType.Single,
        allowedTypes: [FieldType.DateTime, FieldType.Date],
        allowedDataModelTypes: ['date', 'datetime'],
        FilterDisplay: FilterDisplayType.BetweenFields,
    },
    {
        text: 'In the Current',
        value: FilterType.IntheCurrent,
        valueType: ValueType.Single,
        allowedTypes: [FieldType.Date, FieldType.DateTime],
        allowedDataModelTypes: ['date', 'datetime'],
        FilterDisplay: FilterDisplayType.SingleField,
    },
    {
        text: 'In the Last',
        value: FilterType.IntheLast,
        valueType: ValueType.Single,
        allowedTypes: [FieldType.Date, FieldType.DateTime],
        allowedDataModelTypes: ['date', 'datetime'],
        FilterDisplay: FilterDisplayType.SingleField,
    },
    {
        text: 'In the Next',
        value: FilterType.IntheNext,
        valueType: ValueType.Single,
        allowedTypes: [FieldType.Date, FieldType.DateTime],
        allowedDataModelTypes: ['date', 'datetime'],
        FilterDisplay: FilterDisplayType.SingleField,
    },
    {
        text: 'Is Empty',
        value: FilterType.IsEmpty,
        valueType: ValueType.Single,
        allowedTypes: [FieldType.String, FieldType.Number, FieldType.Integer],
        allowedDataModelTypes: ['*'],
        FilterDisplay: FilterDisplayType.NoField,
    },
    {
        text: 'Is Not Empty',
        value: FilterType.IsNotEmpty,
        valueType: ValueType.Single,
        allowedTypes: [FieldType.String, FieldType.Number, FieldType.Integer],
        allowedDataModelTypes: ['*'],
        FilterDisplay: FilterDisplayType.NoField,
    },
    {
        text: 'More Than',
        value: FilterType.MoreThan,
        valueType: ValueType.Single,
        allowedTypes: [FieldType.Number, FieldType.Integer],
        allowedDataModelTypes: [
            'decimal',
            'integer',
            'percentage',
            'percentage-whole-number',
            'currency',
        ],
        FilterDisplay: FilterDisplayType.SingleField,
    },
    {
        text: 'Less Than',
        value: FilterType.LessThan,
        valueType: ValueType.Single,
        allowedTypes: [FieldType.Number, FieldType.Integer],
        allowedDataModelTypes: [
            'decimal',
            'integer',
            'percentage',
            'percentage-whole-number',
            'currency',
        ],
        FilterDisplay: FilterDisplayType.SingleField,
    },
];
