export var FormulaValueType;
(function (FormulaValueType) {
    FormulaValueType["Text"] = "text";
    FormulaValueType["Field"] = "field";
    FormulaValueType["Number"] = "number";
    FormulaValueType["Formula"] = "formula";
    FormulaValueType["Date"] = "date";
})(FormulaValueType || (FormulaValueType = {}));
export var FormulaOperator;
(function (FormulaOperator) {
    FormulaOperator["Add"] = "add";
    FormulaOperator["Subtract"] = "subtract";
    FormulaOperator["Multiply"] = "multiply";
    FormulaOperator["Divide"] = "divide";
})(FormulaOperator || (FormulaOperator = {}));
export var FormulaAggregateFunction;
(function (FormulaAggregateFunction) {
    FormulaAggregateFunction["Min"] = "min";
    FormulaAggregateFunction["Max"] = "max";
    FormulaAggregateFunction["Avg"] = "avg";
    FormulaAggregateFunction["Sum"] = "sum";
})(FormulaAggregateFunction || (FormulaAggregateFunction = {}));
export function isFormulaValid(formula) {
    return true;
}
export function getFormulaDisplay(formula) {
    if (!formula || (!(formula.components ?? []).length && !formula.aggregateFunction)) {
        return '[empty]';
    }
    const components = formula.components.map((x) => getFormulaComponentDisplay(x, formula));
    return formula.aggregateFunction
        ? `${formula.aggregateFunction}(${components.join(', ')})`
        : `( ${components.join(' ')} )`;
}
export function getFormulaComponentDisplay(component, formula) {
    if (!component) {
        return '[empty]';
    }
    const value = [FormulaValueType.Date, FormulaValueType.Text, FormulaValueType.Number].includes(component.type)
        ? component.value ?? '<span class="formula-error">[value]</span>'
        : component.type === FormulaValueType.Formula
            ? getFormulaDisplay(component.value)
            : component.type === FormulaValueType.Field
                ? component.value
                    ? `[${component.value.fieldLabel}]`
                    : '<span class="formula-error">[field]</span>'
                : '[unknown]';
    return !formula.aggregateFunction && component.operator
        ? `${getFormulaOperatorDisplay(component.operator)} ${value}`
        : value;
}
export function getFormulaOperatorDisplay(operator) {
    return operator === FormulaOperator.Add
        ? '+'
        : operator === FormulaOperator.Divide
            ? '/'
            : operator === FormulaOperator.Multiply
                ? 'x'
                : operator === FormulaOperator.Subtract
                    ? '-'
                    : '';
}
