var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FilterType } from '../../../data-filters/models';
import { RoleCategories } from '../../../roleEnums';
import { FieldData, RecordTypeData } from '../../meta-data-decorators';
const recordTypeId = 'ClaimFinancialLimit';
let ClaimFinancialLimit = class ClaimFinancialLimit {
    id;
    userId;
    user;
    noLimits;
    totalReserve;
    singlePayment;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], ClaimFinancialLimit.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'User' })
], ClaimFinancialLimit.prototype, "userId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'User' })
], ClaimFinancialLimit.prototype, "user", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean' })
], ClaimFinancialLimit.prototype, "noLimits", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], ClaimFinancialLimit.prototype, "totalReserve", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], ClaimFinancialLimit.prototype, "singlePayment", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], ClaimFinancialLimit.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], ClaimFinancialLimit.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], ClaimFinancialLimit.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], ClaimFinancialLimit.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], ClaimFinancialLimit.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], ClaimFinancialLimit.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], ClaimFinancialLimit.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], ClaimFinancialLimit.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], ClaimFinancialLimit.prototype, "deletedUTC", void 0);
ClaimFinancialLimit = __decorate([
    RecordTypeData({
        id: recordTypeId,
        usesNewDataModel: true,
        hasBackingTable: true,
        displayNameSingular: 'Claim Financial Limit',
        displayNamePlural: 'Claim Financial Limits',
        roleCategory: RoleCategories.Claims,
        recordHistoryConfig: {
            enable: true
        },
        dataModelCustomization: {
            hideFromAdmin: true,
            allowCustomFields: false,
        },
        quickFilterFields: [
            { fieldPath: 'user.fullName', filterType: FilterType.Contains, recordType: recordTypeId }
        ]
    })
], ClaimFinancialLimit);
export { ClaimFinancialLimit };
