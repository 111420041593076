var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { RoleCategories } from '../../roleEnums';
import { TrailMapType } from '../../trail-maps/models';
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'ClaimCheckPayment';
let ClaimCheckPayment = class ClaimCheckPayment {
    id;
    claimId;
    claim;
    checkId;
    check;
    transactionDate;
    bucket;
    amount;
    transactionType;
    invoiceNumber;
    invoiceDate;
    serviceFrom;
    serviceThru;
    notes;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], ClaimCheckPayment.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Claim' })
], ClaimCheckPayment.prototype, "claimId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Claim' })
], ClaimCheckPayment.prototype, "claim", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Check' })
], ClaimCheckPayment.prototype, "checkId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Check' })
], ClaimCheckPayment.prototype, "check", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], ClaimCheckPayment.prototype, "transactionDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', codeList: [], label: 'Bucket' })
], ClaimCheckPayment.prototype, "bucket", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], ClaimCheckPayment.prototype, "amount", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', codeSet: 'transaction-types' })
], ClaimCheckPayment.prototype, "transactionType", void 0);
__decorate([
    FieldData({ recordTypeId })
], ClaimCheckPayment.prototype, "invoiceNumber", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], ClaimCheckPayment.prototype, "invoiceDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], ClaimCheckPayment.prototype, "serviceFrom", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], ClaimCheckPayment.prototype, "serviceThru", void 0);
__decorate([
    FieldData({ recordTypeId })
], ClaimCheckPayment.prototype, "notes", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], ClaimCheckPayment.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], ClaimCheckPayment.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], ClaimCheckPayment.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], ClaimCheckPayment.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], ClaimCheckPayment.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], ClaimCheckPayment.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], ClaimCheckPayment.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], ClaimCheckPayment.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], ClaimCheckPayment.prototype, "deletedUTC", void 0);
ClaimCheckPayment = __decorate([
    RecordTypeData({
        id: recordTypeId,
        usesNewDataModel: true,
        hasBackingTable: true,
        displayNameSingular: 'Claim Check Payment',
        displayNamePlural: 'Claim Check Payments',
        supports: {
            views: true,
            files: true,
            notes: true,
            tasks: true,
            docTemplates: true
        },
        roleCategory: RoleCategories.Checks,
        recordHistoryConfig: {
            enable: true
        },
        workflow: {
            enabled: true,
            generalEvents: true
        },
        dataModelCustomization: {
            allowCustomFields: true,
        },
        trailMapTypes: [TrailMapType.QuickAdd, TrailMapType.Detail, TrailMapType.HomePage],
    })
], ClaimCheckPayment);
export { ClaimCheckPayment };
