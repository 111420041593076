var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { RoleCategories } from '../../../roleEnums';
import { TrailMapType } from '../../../trail-maps/models';
import { FieldData, RecordTypeData } from '../../meta-data-decorators';
const recordTypeId = 'PolicyholderLedgerEntry';
let PolicyholderLedgerEntry = class PolicyholderLedgerEntry {
    id;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
    policyHolderId;
    policyHolder;
    entryTimestamp;
    debitAmount;
    creditAmount;
    policyPeriod;
    isCommissionable;
    commission;
    policyId;
    policy;
    policyPaymentId;
    policyPayment;
    policyNumber;
    policyholderDepositId;
    policyholderDeposit;
    invoiceId;
    invoice;
    invoiceNumber;
    invoiceLineId;
    invoiceLine;
    description;
    externalReferenceId;
    referenceNote;
    ledgerAccountId;
    ledgerAccount;
    balance;
    creditSourceId;
    scenarioFlag;
    policyAuditId;
    policyAudit;
};
__decorate([
    FieldData({ recordTypeId, label: 'Ledger ID', fieldType: 'uuid' })
], PolicyholderLedgerEntry.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyholderLedgerEntry.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyholderLedgerEntry.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyholderLedgerEntry.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], PolicyholderLedgerEntry.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], PolicyholderLedgerEntry.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], PolicyholderLedgerEntry.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], PolicyholderLedgerEntry.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], PolicyholderLedgerEntry.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], PolicyholderLedgerEntry.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'uuid',
        label: 'Policyholder Id',
        referenceType: 'PolicyHolder',
    })
], PolicyholderLedgerEntry.prototype, "policyHolderId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'PolicyHolder', label: 'Policyholder' })
], PolicyholderLedgerEntry.prototype, "policyHolder", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'datetime', label: 'Date/Time' })
], PolicyholderLedgerEntry.prototype, "entryTimestamp", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', label: 'Billed' })
], PolicyholderLedgerEntry.prototype, "debitAmount", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', label: 'Paid' })
], PolicyholderLedgerEntry.prototype, "creditAmount", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'year' })
], PolicyholderLedgerEntry.prototype, "policyPeriod", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean' })
], PolicyholderLedgerEntry.prototype, "isCommissionable", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', label: 'Policy Id', referenceType: 'Policy' })
], PolicyholderLedgerEntry.prototype, "policyId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Policy', label: 'Policy' })
], PolicyholderLedgerEntry.prototype, "policy", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'uuid',
        label: 'Policy Payment Id',
        referenceType: 'PolicyPayment',
    })
], PolicyholderLedgerEntry.prototype, "policyPaymentId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Policy', label: 'PolicyPayment' })
], PolicyholderLedgerEntry.prototype, "policyPayment", void 0);
__decorate([
    FieldData({ recordTypeId })
], PolicyholderLedgerEntry.prototype, "policyNumber", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'uuid',
        label: 'Policyholder Deposit Id',
        referenceType: 'PolicyholderDeposit',
    })
], PolicyholderLedgerEntry.prototype, "policyholderDepositId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'PolicyholderDeposit', label: 'PolicyholderDeposit' })
], PolicyholderLedgerEntry.prototype, "policyholderDeposit", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', label: 'Invoice Id', referenceType: 'Invoice' })
], PolicyholderLedgerEntry.prototype, "invoiceId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Invoice' })
], PolicyholderLedgerEntry.prototype, "invoice", void 0);
__decorate([
    FieldData({ recordTypeId })
], PolicyholderLedgerEntry.prototype, "invoiceNumber", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'uuid',
        label: 'Invoice Line Id',
        referenceType: 'InvoiceLine',
    })
], PolicyholderLedgerEntry.prototype, "invoiceLineId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'InvoiceLine' })
], PolicyholderLedgerEntry.prototype, "invoiceLine", void 0);
__decorate([
    FieldData({ recordTypeId })
], PolicyholderLedgerEntry.prototype, "description", void 0);
__decorate([
    FieldData({ recordTypeId })
], PolicyholderLedgerEntry.prototype, "externalReferenceId", void 0);
__decorate([
    FieldData({ recordTypeId })
], PolicyholderLedgerEntry.prototype, "referenceNote", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'uuid',
        label: 'Ledger Account Id',
        referenceType: 'LedgerAccount',
    })
], PolicyholderLedgerEntry.prototype, "ledgerAccountId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'LedgerAccount' })
], PolicyholderLedgerEntry.prototype, "ledgerAccount", void 0);
__decorate([
    FieldData({ recordTypeId, disableField: true })
], PolicyholderLedgerEntry.prototype, "balance", void 0);
__decorate([
    FieldData({ recordTypeId })
], PolicyholderLedgerEntry.prototype, "creditSourceId", void 0);
__decorate([
    FieldData({ recordTypeId })
], PolicyholderLedgerEntry.prototype, "scenarioFlag", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', label: 'Audit Id', referenceType: 'PolicyAudit' })
], PolicyholderLedgerEntry.prototype, "policyAuditId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'PolicyAudit' })
], PolicyholderLedgerEntry.prototype, "policyAudit", void 0);
PolicyholderLedgerEntry = __decorate([
    RecordTypeData({
        usesNewDataModel: true,
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Policyholder Ledger Entry',
        displayNamePlural: 'Policyholder Ledger Entries',
        trailMapTypes: [
            TrailMapType.Detail
        ],
        supports: {
            views: true
        },
        dataModelCustomization: {
            hideFromAdmin: true
        },
        roleCategory: RoleCategories.Accounting,
    })
], PolicyholderLedgerEntry);
export { PolicyholderLedgerEntry };
