<div
  *ngIf="formula"
  class="flex flex-col border rounded-md mb-2 p-2"
  [ngClass]="{
    'bg-gray-100': depth % 2 === 0,
    'bg-card': depth % 2 !== 0,
    'border-l-2 border-l-gray-400': depth > 0,
    'pr-2': depth === 0
  }"
  [formGroup]="this.form">
  <div class="flex flex-row items-center justify-center mb-4">
    <span>Optional aggregate function for group: </span>
    <mat-form-field class="fuse-mat-dense w-32 no-margin-bottom mx-2">
      <mat-select formControlName="aggregateFunction">
        <mat-option value=""></mat-option>
        <mat-option *ngFor="let function of functions" [value]="function.value">{{
          function.text
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="ml-2 mb-2" formArrayName="components">
    <ng-container *ngFor="let component of formula.components; index as i">
      <tb-formula-component
        [component]="component"
        [index]="i"
        [depth]="depth"
        [outputFormat$]="outputFormat$"
        [recordTypeId$]="recordTypeId$"
        [allowOperators$]="allowOperators$"
        (deleteComponent)="removeComponent($event)"
        (componentUpdated)="onComponentUpdated($event)"></tb-formula-component>
    </ng-container>
  </div>
  <div class="flex flex-row justify-center w-full gap-3 mb-6">
    <button
      mat-flat-button
      class="text-sm"
      [matTooltip]="'New Field'"
      (click)="addFieldComponent()">
      <mat-icon [svgIcon]="'mat_outline:add'" class="mr-2 icon-size-3"></mat-icon> New Field
    </button>
    <button
      mat-flat-button
      class="text-sm"
      [matTooltip]="'New Value'"
      (click)="addValueComponent()">
      <mat-icon [svgIcon]="'mat_outline:plus_one'" class="mr-2 icon-size-3"></mat-icon> New Value
    </button>
    <button
      mat-flat-button
      class="text-sm"
      [matTooltip]="'New Group'"
      (click)="addFormulaComponent()">
      <mat-icon svgIcon="mat_outline:calculate" class="mr-2 icon-size-3"></mat-icon> New Group
    </button>
  </div>
  <div class="flex flex-col justify-center">Preview</div>
  <div
    class="flex rounded-md p-6 mb-4 text-lg items-center justify-center border-t-2 border-gray-600"
    [innerHTML]="getFormulaDisplay(formula) | sanitizeHtml"
    [ngClass]="{
      'bg-gray-100': depth % 2 === 1,
      'bg-card': depth % 2 !== 1,
      'border-l-2 border-l-gray-400': depth > 0,
      'pr-2': depth === 0
    }"></div>
</div>
