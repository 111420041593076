var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'RateTableEnhanced';
let RateTableEnhanced = class RateTableEnhanced {
    id;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
    rateName;
    description;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], RateTableEnhanced.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], RateTableEnhanced.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], RateTableEnhanced.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], RateTableEnhanced.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], RateTableEnhanced.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], RateTableEnhanced.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], RateTableEnhanced.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], RateTableEnhanced.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], RateTableEnhanced.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], RateTableEnhanced.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Rate Name', fieldType: 'string' })
], RateTableEnhanced.prototype, "rateName", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Description', fieldType: 'string' })
], RateTableEnhanced.prototype, "description", void 0);
RateTableEnhanced = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Enhanced Rate Table',
        displayNamePlural: 'Enhanced Rate Tables',
        dataModelCustomization: {
            hideFromAdmin: true,
        },
        usesNewDataModel: true,
    })
], RateTableEnhanced);
export { RateTableEnhanced };
