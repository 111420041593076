<!-- Basic search -->
<ng-container *ngIf="appearance === 'basic'" [formGroup]="this.form" autocomplete="off">
  <div
    class="w-60 max-w-60 md:max-w-200 transition-width duration-300 ease-in-out"
    [ngClass]="{
      'w-200': this.inputFocus$ | async
    }">
    <mat-form-field class="fuse-mat-no-subscript w-full">
      <mat-icon matPrefix [svgIcon]="'heroicons_outline:magnifying-glass'"></mat-icon>
      <input
        matInput
        (focus)="this.inputFocus$.next(true)"
        autocomplete="off"
        #searchInput
        (keyup)="onKeyUp($event)"
        (keydown)="onKeydown($event)"
        formControlName="input"
        [attr.title]="'Shortcut: MetaKey + /'"
        placeholder="Quick Search" />
      <button
        *ngIf="this.inputFocus$ | async"
        matSuffix
        mat-icon-button
        [attr.title]="'Close'"
        aria-label="Close"
        (click)="closeSearch($event)"
        type="button">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <div
      *ngIf="this.inputFocus$ | async"
      class="bg-card absolute rounded-md border-2 shadow-sm px-4 py-4 flex flex-row min-h-8"
      [style.width]="'inherit'">
      <div class="basis-2/3 h-full flex flex-col overflow-hidden">
        <div class="max-h-180 overflow-scroll border-r mr-2" [swIsLoading]="this.SEARCH_KEY">
          <ng-container *ngIf="this.searchResults$ | async as searchResults">
            <ng-container *ngFor="let resultSet of searchResults; trackBy: trackByFn">
              <div class="text-sm font-semibold tracking-wider text-secondary">
                {{ resultSet.label.toUpperCase() }}
              </div>
              <ng-container
                *ngFor="let result of resultSet.results; let last = last; trackBy: trackByFn">
                <tb-search-result
                  [result]="result"
                  [last]="last"
                  (resultSelected)="this.selectResult($event)">
                </tb-search-result>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="searchResults.length === 0">
              <div class="text-secondary">No results match the search criteria</div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="(this.searchResults$ | async) === undefined">
            <div class="text-secondary text-md">Start typing to search for records...</div>
          </ng-container>
          <ng-container *ngIf="(this.searchResults$ | async) === undefined">
            <ng-container *ngIf="this.recentResults$ | async as recentResults">
              <div class="leading-normal text-sm uppercase mt-3 mb-1">recently viewed</div>
              <ng-container *ngFor="let result of recentResults; let last = last; trackById">
                <tb-search-result
                  [result]="result"
                  [last]="last"
                  (resultSelected)="this.selectResult($event)">
                </tb-search-result>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div class="basis-1/3 h-full flex flex-col ml-2">
        <div class="leading-normal text-sm uppercase mb-2">include search areas</div>
        <div class="flex flex-col">
          <ng-container
            formArrayName="categories"
            *ngFor="let _ of this.categoriesArray.controls; index as i">
            <mat-checkbox [formControlName]="i" [checked]="_.value">{{
              this.categorySet[i].description
            }}</mat-checkbox>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>
