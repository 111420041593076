export var CheckStatusEnum;
(function (CheckStatusEnum) {
    CheckStatusEnum["Draft"] = "draft";
    CheckStatusEnum["Printing"] = "printing";
    CheckStatusEnum["Issued"] = "issued";
    CheckStatusEnum["Cleared"] = "cleared";
    CheckStatusEnum["Voided"] = "voided";
    CheckStatusEnum["StopPay"] = "stop-pay";
    CheckStatusEnum["Cancelled"] = "cancelled";
})(CheckStatusEnum || (CheckStatusEnum = {}));
;
export const checkStatusList = [
    { code: CheckStatusEnum.Draft, description: 'Draft' },
    { code: CheckStatusEnum.Printing, description: 'Printing' },
    { code: CheckStatusEnum.Issued, description: 'Issued' },
    { code: CheckStatusEnum.Cleared, description: 'Cleared' },
    { code: CheckStatusEnum.Voided, description: 'Voided' },
    { code: CheckStatusEnum.StopPay, description: 'Stop Pay' },
    { code: CheckStatusEnum.Cancelled, description: 'Cancelled' },
];
export function getCheckStatusDescription(status) {
    if (!status) {
        return status;
    }
    return checkStatusList.find(f => f.code === status)?.description ?? status;
}
export var PayeeTypeEnum;
(function (PayeeTypeEnum) {
    PayeeTypeEnum["Contact"] = "contact";
    PayeeTypeEnum["Claimant"] = "claimant";
})(PayeeTypeEnum || (PayeeTypeEnum = {}));
export const payeeTypeList = [
    { code: PayeeTypeEnum.Contact, description: 'Contact' },
    { code: PayeeTypeEnum.Claimant, description: 'Claimant' }
];
export const checkTransitions = new Map([
    [CheckStatusEnum.Issued, [CheckStatusEnum.Cleared, CheckStatusEnum.Voided, CheckStatusEnum.StopPay]],
    [CheckStatusEnum.Draft, [CheckStatusEnum.Cancelled]],
    [CheckStatusEnum.StopPay, [CheckStatusEnum.Voided]]
]);
export const systemCheckTransitions = new Map([
    [CheckStatusEnum.Draft, [CheckStatusEnum.Issued]]
]);
