import { Injectable } from '@angular/core';
import { DataFilterHistory } from '../models/model';

@Injectable({
  providedIn: 'root',
})
export class DataFilterHistoryService {
  private STORAGE_PREFIX = 'data-filter-history';
  private storageKey(base: string) {
    return `${this.STORAGE_PREFIX}.${base}`;
  }
  get(key: string): DataFilterHistory | null {
    const hit = sessionStorage.getItem(this.storageKey(key));
    if (!!hit) {
      return JSON.parse(hit);
    }
    return null;
  }

  set(key: string, data: DataFilterHistory) {
    if (data.filters.length === 0 && data.quickSearchFilters.length === 0 && !data.viewId) {
      this.clear(key);
      return;
    }
    sessionStorage.setItem(this.storageKey(key), JSON.stringify(data));
  }

  clear(key: string) {
    sessionStorage.removeItem(this.storageKey(key));
  }
}
