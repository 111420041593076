var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { FilterType } from '../../data-filters/models';
import { PayrollReportStatus } from '../../payroll-reports/models';
import { RoleCategories } from '../../roleEnums';
import { policyStatusList } from '../../policies/policyEnums';
export var PayrollReportPaidStatusEnum;
(function (PayrollReportPaidStatusEnum) {
    PayrollReportPaidStatusEnum["pending"] = "pending";
    PayrollReportPaidStatusEnum["success"] = "success";
    PayrollReportPaidStatusEnum["failed"] = "failed";
})(PayrollReportPaidStatusEnum || (PayrollReportPaidStatusEnum = {}));
const recordTypeId = 'PayrollReport';
let PayrollReport = class PayrollReport {
    id;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
    submittedDate;
    submitUserId;
    submitUser;
    effectiveDate;
    expirationDate;
    paidStatus;
    paidStatusErrorReason;
    paymentData;
    status;
    isNoPayroll;
    paidDate;
    subTotal;
    reportPremium;
    minimumPremiumAmount;
    totalPremium;
    lineItemTotal;
    total;
    xmod;
    worksheetFactor;
    worksheetFactorElements;
    classCodePayrolls;
    // FK
    invoiceId;
    invoice;
    invoiceStatus;
    policyId;
    policyPeriod;
    policyNumber;
    policyStatus;
    policyHolderId;
    policyHolder;
    paymentCleared;
    billingSchedule;
    lineItems;
    sourceId;
    policyLocation;
};
__decorate([
    FieldData({ recordTypeId, label: 'Payroll Report ID', fieldType: 'uuid' })
], PayrollReport.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PayrollReport.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PayrollReport.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PayrollReport.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], PayrollReport.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], PayrollReport.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], PayrollReport.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], PayrollReport.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], PayrollReport.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], PayrollReport.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'datetime', label: 'Submitted On' })
], PayrollReport.prototype, "submittedDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PayrollReport.prototype, "submitUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Submitted By', fieldType: 'User' })
], PayrollReport.prototype, "submitUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date', jsonStorage: true })
], PayrollReport.prototype, "effectiveDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date', jsonStorage: true })
], PayrollReport.prototype, "expirationDate", void 0);
__decorate([
    FieldData({ recordTypeId, codeEnum: PayrollReportPaidStatusEnum, jsonStorage: true })
], PayrollReport.prototype, "paidStatus", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Payment Error Reason', jsonStorage: true })
], PayrollReport.prototype, "paidStatusErrorReason", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Payment Data', jsonStorage: true })
], PayrollReport.prototype, "paymentData", void 0);
__decorate([
    FieldData({ recordTypeId, codeEnum: PayrollReportStatus, jsonStorage: true })
], PayrollReport.prototype, "status", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'No Payroll?', fieldType: 'boolean', jsonStorage: true })
], PayrollReport.prototype, "isNoPayroll", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date', jsonStorage: true })
], PayrollReport.prototype, "paidDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], PayrollReport.prototype, "subTotal", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], PayrollReport.prototype, "reportPremium", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], PayrollReport.prototype, "minimumPremiumAmount", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], PayrollReport.prototype, "totalPremium", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], PayrollReport.prototype, "lineItemTotal", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], PayrollReport.prototype, "total", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'decimal',
        label: 'Experience Modifier',
        jsonStorage: true,
    })
], PayrollReport.prototype, "xmod", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'decimal', jsonStorage: true })
], PayrollReport.prototype, "worksheetFactor", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'PayrollReportFactor', isList: true, jsonStorage: true })
], PayrollReport.prototype, "worksheetFactorElements", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'PayrollReportRate', isList: true, jsonStorage: true })
], PayrollReport.prototype, "classCodePayrolls", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Invoice' })
], PayrollReport.prototype, "invoiceId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Invoice' })
], PayrollReport.prototype, "invoice", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], PayrollReport.prototype, "invoiceStatus", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', jsonStorage: true })
], PayrollReport.prototype, "policyId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Policy Period', jsonStorage: true })
], PayrollReport.prototype, "policyPeriod", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], PayrollReport.prototype, "policyNumber", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, fieldType: 'codelist', codeList: policyStatusList })
], PayrollReport.prototype, "policyStatus", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', label: 'Policyholder Id', jsonStorage: true })
], PayrollReport.prototype, "policyHolderId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'PolicyHolder', label: 'Policyholder', jsonStorage: true })
], PayrollReport.prototype, "policyHolder", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean', jsonStorage: true })
], PayrollReport.prototype, "paymentCleared", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', jsonStorage: true, disableField: true })
], PayrollReport.prototype, "sourceId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Location', label: 'Policy Location', jsonStorage: true, ignoreBackingTable: true })
], PayrollReport.prototype, "policyLocation", void 0);
PayrollReport = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Payroll Report',
        displayNamePlural: 'Payroll Reports',
        supports: {
            views: true,
            globalSearch: true,
            docTemplates: true,
        },
        globalSearchPaths: ['policyNumber'],
        dataModelCustomization: {
            allowCustomFields: true,
        },
        lookupProperties: {
            resultProperties: ['policyHolder.displayName', 'policyNumber', 'effectiveDate'],
            filterProperties: ['policyHolder.displayName', 'policyNumber'],
        },
        usesNewDataModel: true,
        roleCategory: RoleCategories.Payroll,
        workflow: {
            enabled: true,
            generalEvents: true,
            otherEvents: [
                'PAYROLLREPORT-PAID',
                'PAYROLLREPORT-PAYMENT-FAILED',
                'PAYROLLREPORT-PAYMENT-REVERSED',
                'PAYROLLREPORT-SUBMITTED',
                'PAYROLLREPORT-RESUBMITTED',
            ],
        },
        quickFilterFields: [
            {
                recordType: recordTypeId,
                fieldPath: 'policyHolder.displayName',
                filterType: FilterType.Contains,
            },
            {
                recordType: recordTypeId,
                fieldPath: 'policyNumber',
                filterType: FilterType.Equals,
            },
            {
                recordType: recordTypeId,
                fieldPath: 'status',
                filterType: FilterType.Equals,
            },
            {
                recordType: recordTypeId,
                fieldPath: 'paidStatus',
                filterType: FilterType.Equals,
            },
        ],
        recordHistoryConfig: {
            enable: true,
        },
    })
], PayrollReport);
export { PayrollReport };
