var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FilterType } from '../../data-filters/models';
import { TrailMapType } from '../../trail-maps/models';
import { FieldData, RecordTypeData } from '../meta-data-decorators';
export var ViewType;
(function (ViewType) {
    ViewType["Detail"] = "detail";
    ViewType["Summary"] = "summary";
    ViewType["Sql"] = "sql";
})(ViewType || (ViewType = {}));
const recordTypeId = 'View';
let View = class View {
    id;
    createUserId;
    createUser;
    createdUTC;
    modifiedUserId;
    modifiedUser;
    modifiedUTC;
    deleteUserId;
    deleteUser;
    deletedUTC;
    friendlyName;
    recordType;
    isShared;
    viewType;
    header;
    customSql;
    customColumns;
    filter;
    fields;
    groupSummaryFunctions;
    groupFields;
    groupId;
    sortFields;
};
__decorate([
    FieldData({ recordTypeId, label: 'View ID', fieldType: 'uuid' })
], View.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'User' })
], View.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], View.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], View.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'User' })
], View.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], View.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], View.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'User' })
], View.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], View.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], View.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Name' })
], View.prototype, "friendlyName", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'recordTypeLookup' })
], View.prototype, "recordType", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean' })
], View.prototype, "isShared", void 0);
__decorate([
    FieldData({
        recordTypeId,
        codeList: [
            { code: ViewType.Detail, description: 'Detail' },
            { code: ViewType.Summary, description: 'Summary' },
            { code: ViewType.Sql, description: 'SQL' },
        ],
        label: 'View Type',
    })
], View.prototype, "viewType", void 0);
__decorate([
    FieldData({ recordTypeId, disableField: true, jsonStorage: true, fieldType: 'jsonb' })
], View.prototype, "header", void 0);
__decorate([
    FieldData({ recordTypeId, disableField: true, jsonStorage: true, fieldType: 'jsonb' })
], View.prototype, "customSql", void 0);
__decorate([
    FieldData({ recordTypeId, disableField: true, jsonStorage: true, fieldType: 'jsonb' })
], View.prototype, "customColumns", void 0);
__decorate([
    FieldData({ recordTypeId, disableField: true, jsonStorage: true, fieldType: 'jsonb' })
], View.prototype, "filter", void 0);
__decorate([
    FieldData({ recordTypeId, disableField: true, jsonStorage: true, fieldType: 'jsonb' })
], View.prototype, "fields", void 0);
__decorate([
    FieldData({ recordTypeId, disableField: true, jsonStorage: true, fieldType: 'jsonb' })
], View.prototype, "groupSummaryFunctions", void 0);
__decorate([
    FieldData({ recordTypeId, disableField: true, jsonStorage: true, fieldType: 'jsonb' })
], View.prototype, "groupFields", void 0);
__decorate([
    FieldData({ recordTypeId, disableField: true, jsonStorage: true, fieldType: 'jsonb' })
], View.prototype, "groupId", void 0);
__decorate([
    FieldData({ recordTypeId, disableField: true, jsonStorage: true, fieldType: 'jsonb' })
], View.prototype, "sortFields", void 0);
View = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        usesNewDataModel: true,
        displayNameSingular: 'View',
        displayNamePlural: 'Views',
        dataModelCustomization: {
            hideFromAdmin: true,
        },
        supports: {
            views: true,
        },
        trailMapTypes: [TrailMapType.HomePage],
        lookupProperties: { resultProperties: ['friendlyName'] },
        quickFilterFields: [
            {
                recordType: recordTypeId,
                fieldPath: 'friendlyName',
                filterType: FilterType.Contains,
            },
            {
                recordType: recordTypeId,
                fieldPath: 'isShared',
                filterType: FilterType.Equals,
            },
            {
                recordType: recordTypeId,
                fieldPath: 'recordType',
                filterType: FilterType.EqualsCaseInsensitive,
            },
            {
                recordType: recordTypeId,
                fieldPath: 'viewType',
                filterType: FilterType.Equals,
            },
        ],
    })
], View);
export { View };
