var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FilterType } from '../../data-filters/models';
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'Role';
let Role = class Role {
    id;
    name;
    description;
    createUser;
    createUserId;
    createdUTC;
    modifiedUser;
    modifiedUserId;
    modifiedUTC;
    deleteUser;
    deleteUserId;
    deletedUTC;
    permissions;
    dataAccess;
    navigation;
    contactTypePermissions;
    incidentTypePermissions;
    coveragePermissions;
    notePermissions;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Role.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId })
], Role.prototype, "name", void 0);
__decorate([
    FieldData({ recordTypeId })
], Role.prototype, "description", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], Role.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], Role.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], Role.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], Role.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], Role.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], Role.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], Role.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], Role.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], Role.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true, disableField: true })
], Role.prototype, "permissions", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true, disableField: true })
], Role.prototype, "dataAccess", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true, disableField: true })
], Role.prototype, "navigation", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true, disableField: true })
], Role.prototype, "contactTypePermissions", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true, disableField: true })
], Role.prototype, "incidentTypePermissions", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true, disableField: true })
], Role.prototype, "coveragePermissions", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true, disableField: true })
], Role.prototype, "notePermissions", void 0);
Role = __decorate([
    RecordTypeData({
        id: recordTypeId,
        usesNewDataModel: true,
        dataModelCustomization: {
            hideFromAdmin: true
        },
        hasBackingTable: true,
        displayNameSingular: 'Role',
        displayNamePlural: 'Roles',
        quickFilterFields: [
            {
                recordType: recordTypeId,
                fieldPath: 'name',
                filterType: FilterType.Contains,
            },
        ],
    })
], Role);
export { Role };
