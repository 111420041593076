import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService, AuthState } from 'app/core/auth/auth.service';
import { orderBy } from 'lodash-es';
import { RecentlyViewedRecord } from 'portal-commons/dist/data-model/record-types/recently-viewed-record';
import { BehaviorSubject, catchError, debounceTime, distinctUntilChanged, first, map, of, shareReplay, switchMap, tap } from 'rxjs';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class RecentlyViewedService {
  authService = inject(AuthService);
  httpClient = inject(HttpClient);
  route = inject(ActivatedRoute);

  private _recentlyViewed = new BehaviorSubject<RecentlyViewedRecord[] | undefined>(undefined);
  recentlyViewed$ = this._recentlyViewed.asObservable().pipe(shareReplay(1));

  constructor() {
    this.authService.auth$.pipe(
      untilDestroyed(this),
      debounceTime(500),
      distinctUntilChanged((a, b) => this.authChanged(a, b)),
      switchMap((authState) => {
        return this.refresh(authState);
      }),
    ).subscribe();
  }

  evalRoute(routeSnap: ActivatedRouteSnapshot) {
    let currSnapshot: ActivatedRouteSnapshot | null = routeSnap;
    while (currSnapshot) {
      if (currSnapshot.data.trackRecent && currSnapshot.data.trackRecent === true) {
        console.log('track-recent-found', currSnapshot.params, currSnapshot.data.recordTypeId, currSnapshot.data.trackRecent);
        if (currSnapshot.params.id && currSnapshot.data.recordTypeId) {
          void this.postUpdate(currSnapshot.data.recordTypeId, currSnapshot.params.id).subscribe();
        }
      }
      currSnapshot = currSnapshot.firstChild;
    }
  }

  private authChanged(a: AuthState, b: AuthState): boolean {
    if (!a && !b) { return false; }
    if (a.isLoggedIn !== b.isLoggedIn) { return true; }
    if (a.tenant !== b.tenant) { return true; }
    if (a.id !== b.id) { return true; }
    return false;
  }

  refresh(state: AuthState) {
    if (!state || !state.isLoggedIn) {
      this._recentlyViewed.next(undefined);
      return of(true);
    }

    return this.fetch().pipe(
      map((result) => {
        this._recentlyViewed.next(result);
        return true;
      }),
      catchError((error) => {
        console.error('Unable to update recently viewed', error);
        return of(false);
      })
    );
  }

  fetch() {
    return this.httpClient.get<RecentlyViewedRecord[]>('/api/recently-viewed').pipe(shareReplay(1));
  }

  postUpdate(recordType: string, recordId: string) {
    return this.httpClient.post<RecentlyViewedRecord>('/api/recently-viewed', {
      recordType, recordId
    }).pipe(
      first(),
      tap((result) => {
        this.upsertActivity(result);
      }),
      catchError((error) => {
        console.error('Unable to update recently viewed', error);
        return of(undefined);
      }),
      shareReplay(1)
    );
  }

  private upsertActivity(newRecord: RecentlyViewedRecord) {
    console.log('upsert', newRecord);
    const data = this._recentlyViewed.getValue() ?? [];
    const findIndex = data.findIndex(f => f.id === newRecord.id);
    if (findIndex >= 0) {
      data[findIndex] = newRecord;
    }
    else {
      data.push(newRecord);
    }
    this._recentlyViewed.next(orderBy(data, ['ts'], ['desc']));
  }
}
