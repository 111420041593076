import { Formula } from 'portal-commons/dist/data-model/formula';
import { ModelExpression } from 'portal-commons/dist/data-model/record-types';
import { Code } from 'portal-commons/dist/data-model/record-types/code';
import { FieldFormat, FieldType } from 'portal-commons/dist/views/models';

export interface RecordTypeFieldMetadata {
  fieldType: MetadataFieldType;
  recordTypeId: string;
  id: string;
  refName: string;
  label: string;
  legacyFormat?: FieldFormat;
  legacyType?: FieldType;
  customLabel?: string;
  sortOrder?: SortOrder;
  dataModelFieldType?: string;
  formula?: ModelExpression;
  formula2?: Formula;
  isList?: boolean;
  fieldRefName?: string;
  codeList?: Code[];
}

export enum SortOrder {
  asc = 'asc',
  desc = 'desc',
}

export enum MetadataFieldType {
  default = 'default',
  calculated = 'calculated',
  legacy = 'legacy',
}
